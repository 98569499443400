import React, { } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles } from '@material-ui/core';
import UpdateProperties from './UpdateProperties/UpdateProperties';
import LinkToInstance from './LinkToInstances/LinkToInstance';
import CreateAndLinkToList from './CreateAndLinkToList/CreateAndLinkToList';
import { actionIds } from '../utils';
import LinkToList from './LinkToList/LinkToList';

const styles = theme => ({
})

function ActionDetails(props) {

  const {
    state,
  } = props;

  let bodyContent;

  if (state.selectedAction === actionIds.bulkUpdateProperties) bodyContent = <UpdateProperties {...props} />;
  if (state.selectedAction === actionIds.linkToCollections) bodyContent = <LinkToInstance {...props} />;
  if (state.selectedAction === actionIds.createAndLinkToList) bodyContent = <CreateAndLinkToList {...props} />;
  if (state.selectedAction === actionIds.linkToList) bodyContent = <LinkToList {...props} />;

  return bodyContent;
}

ActionDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(ActionDetails));