import { checkCollectionLinkable, checkProfileEditable } from "../../permissionChecker";

export const actionIds = {
  bulkUpdateProperties: 'bulk_update_properties',
  linkToCollections: 'link_to_collections',
  linkToList: 'link_to_list',
  createAndLinkToList: 'create_and_link_to_list'
}

export const getValidActions = ({ sessionData }) => {
  let actions = [];
  if(checkProfileEditable({ sessionData })) {
    actions.push({
      title: 'Bulk update properties',
      id: actionIds.bulkUpdateProperties,
    })
  }
  if(checkCollectionLinkable({ sessionData })) {
    actions.push({
      title: 'Link to collections',
      id: actionIds.linkToCollections,
    })
  }
  actions.push({
    title: 'Link to list',
    id: actionIds.linkToList,
  })
  actions.push({
    title: 'Create and link to list',
    id: actionIds.createAndLinkToList,
  })
  return actions;
}