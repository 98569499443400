import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, CircularProgress, IconButton, withTheme } from '@material-ui/core';
import axiosCerebrum from '../../../axios-cerebrum';
import KTooltip from '../../UI/KTooltip/KTooltip';
import moment from 'moment';
import { getIconComponent, isInViewport } from '../../../utilities';
import WarningConfirmModal from '../../UI/ConfirmModals/WarningConfirmModal';
import SaveFilterModal from '../../BasicSearch/MainSearch/SaveFilterModal/SaveFilterModal';
import AscIcon from '@material-ui/icons/ArrowUpward'
import DescIcon from '@material-ui/icons/ArrowDownward'
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu'
import CustomMenu from '../../UI/ContextMenu/ContextMenu'
import useAlert from '../../../hooks/useAlert';
import AddAlertModal from '../AddAlertModal/AddAlertModal';
import { checkEmailConfigured } from '../../../permissionChecker';
import * as actions from '../../../store/actions/actionTypes';
import { useDispatch } from 'react-redux';

const styles = theme => ({
  header:{
    fontSize:20,
    color:theme.palette.header.main,
    marginBottom:16
  },
  columnHeader:{
    paddingLeft:16,
    height:48,
    display:'flex',
    alignItems:'center',
    overflow:'hidden',
    background:theme.palette.background.main,
    zIndex:9
  },
  columnText:{
    fontSize:12,
    color:theme.palette.primary.main,
    overflow:'hidden',
    textOverflow:"ellipsis",
    whiteSpace:'nowrap',
    marginRight:12
  },
  listItem:{
    paddingLeft:16,
    height:48,
    display:'flex',
    alignItems:'center',
    cursor:'pointer',
    borderBottom:`1px solid ${theme.palette.listItemDivider.main}`,
    '&:hover':{
      backgroundColor:theme.palette.hovered.main
    }
  },
  listText:{
    fontSize:13.75,
    color:theme.palette.primaryText.main,
    overflow:'hidden',
    textOverflow:"ellipsis",
    whiteSpace:'nowrap',
    marginRight:12
  },
  sortIconWrapper:{
    marginLeft:4,
    position:'relative',
    top:3
  },
  // selector: {
  //   ...theme.components.selector,
	// 	width: 180,
  // },
})

function Body(props) {

  const {
    classes,
    state,
    theme,
    sessionData,
    dispatch,
    history
  } = props;

  const scrollRef = useRef()
  const [buttonHovering, setButtonHovering] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [addAlertModalOpen, setAddAlertModalOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMessage, setAlertMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const reduxDispatch = useDispatch()

  const {
    sendAlert
  } = useAlert({
    id:`filter-list-body`,
  })

  const loadFilterList = ({page=1, search=state.filterListSearch, sort=state.filterListSort}) => {
    dispatch({
      type:'set_filter_list',
      filterListLoading:true,
      filterList:page===1?undefined:state.filterList,
    })

    axiosCerebrum
      .get(
        `/api/filteredlists`,{
          params:{
            page,
            per_page:20,
            sort
          }
        }
      )
      .then(response=>{
        dispatch({
          type:'set_filter_list',
          filterList:{
            ...response.data,
            items:[
              ...(page===1?[]:state.filterList?.items||[]),
              ...response.data.items
            ]
          }
        })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_filter_list',
          filterListError:true
        })
      })
  }

  const onDelete = (el) => {
    setLoading(true)
    axiosCerebrum
      .delete(
        `/api/filteredlists/${el.id}`
      )
      .then(response=>{
        dispatch({
          type:'set_filter_list',
          filterList:{
            ...state.filterList,
            items:state.filterList.items.filter(item=>item.id!==el.id)
          }
        })
        setDeleteModalOpen(false)
        setLoading(false)
        sendAlert({type:'info',message:`Filter "${el.name}" successfully deleted`})
      })
      .catch(error=>{
        console.log(error)
        setAlertMessage('Error occurred deleting the filter')
        setAlertOpen(true)
        setLoading(false)
      })
  }

  useEffect(()=>{
    if(!state.filterList){
      loadFilterList({})
    }
  // eslint-disable-next-line
  },[])

  const shouldLoadMore = () => {
    return isInViewport(scrollRef) && state.filterList?.page < state.filterList?.pages && !state.filterListLoading
  }

  window.onscroll = () => {
    if(shouldLoadMore()){
      loadFilterList({page:state.filterList?.page+1})
    }
  }

  useEffect(()=>{
    if(shouldLoadMore()){
      loadFilterList({page:state.filterList?.page+1})
    }
  // eslint-disable-next-line
  },[state.filterlist,state.filterListLoading])

  const onSortChange = (value) => {
    loadFilterList({sort:value})
    dispatch({type:'set_filter_list_sort',filterListSort:value})
  }

  const getSortIcon = field => {
    if(field==='name'){
      if(state.filterListSort==='ALPHABETICAL'){
        return <AscIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
      if(state.filterListSort==='REVERSE_ALPHABETICAL'){
        return <DescIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
    }
    if(field==='updated'){
      if(state.filterListSort==='END_ASC'){
        return <AscIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
      if(state.filterListSort==='END_DESC'){
        return <DescIcon style={{width:16,height:16,color:theme.palette.primary.main}}/>
      }
    }
  }

  const onChangeSort = field => {
    if(field==='name'){ 
      if(state.filterListSort==='ALPHABETICAL'){
        onSortChange('REVERSE_ALPHABETICAL')
      }else{
        onSortChange('ALPHABETICAL')
      }
    }
    if(field==='updated'){
      if(state.filterListSort==='END_ASC'){
        onSortChange('END_DESC')
      }else{
        onSortChange('END_ASC')
      }
    }
  }


  const onBulkActionFilter = (filter) => {
    reduxDispatch({
      type:actions.SET_BULK_EDIT_PARAM,
      data:{
        url:'/solr/search/select',
        type:'solr',
        filterId: filter.id,
        redirectUrl:window.location.pathname,
        fileName:`Assets from filter: ${filter.name}`.trim(),
      }
    })
    
		history.push('/bulk_update')
  }

  return (
    <div className={classes.root}>
      {
        state.filterList?.items?.length===0 &&
        <Typography>No filters found</Typography>
      }
      {
        state.filterList?.items?.length>0 && 
        <div className={classes.columnHeader} style={{position:'sticky',top:134}}>
          {/* <div style={{flex:'0 0 24px',marginRight:16}}>
          </div> */}
          <Typography className={classes.columnText} onClick={()=>{onChangeSort('name')}} style={{flex:'1 1 300px',cursor:'pointer'}} >NAME <span className={classes.sortIconWrapper}>{getSortIcon('name')}</span></Typography>
          <Typography className={classes.columnText} style={{flex:'1 1 150px'}}>CREATED BY</Typography>
          <Typography className={classes.columnText} style={{flex:'1 1 100px'}}>VIEWABLE</Typography>
          <Typography className={classes.columnText} onClick={()=>{onChangeSort('updated')}} style={{flex:'1 1 150px',cursor:'pointer'}}>LAST UPDATED <span className={classes.sortIconWrapper}>{getSortIcon('updated')}</span></Typography>
          <Typography className={classes.columnText} style={{flex:'0 0 160px'}}>ACTIONS</Typography>
        </div>
      }
      {
        state.filterList?.items?.map(el=>(
          <>
           <ContextMenuTrigger id={el.id}>
            <div 
              className={classes.listItem} 
              style={{background:buttonHovering?theme.palette.background.main:undefined}}
              data-test-classname="filter-list-item"
              onClick={()=>{
                history.push(`/basic_search?filterListId=${el.id}`)
              }}
            >
              {/* <div style={{flex:'0 0 24px',marginRight:16}}>
                {getIconComponent({label:'star',size:24,colour:theme.palette.primaryText.light})}
              </div> */}
              <KTooltip title={el.name}>
                <Typography data-test-classname="filter-list-item-name" className={classes.listText} style={{flex:'1 1 300px'}}>
                  {el.name} 
                </Typography>
              </KTooltip> 
              <KTooltip title={el.created_by.name}>
                <Typography data-test-classname="filter-list-item-creator" className={classes.listText} style={{flex:'1 1 150px'}}>
                  {el.created_by.name}
                </Typography>
              </KTooltip>
              <Typography data-test-classname="filter-list-item-public" className={classes.listText} style={{flex:'1 1 100px'}}>
                {el.public?'Public':'Private'}
              </Typography>
              <Typography data-test-classname="filter-list-item-updated" className={classes.listText} style={{flex:'1 1 150px'}}>
                {moment(el.updated_at).format('DD MMM YYYY')}
              </Typography>
              <div style={{flex:'0 0 160px',marginRight:12,display:'flex',alignItems:'center'}}>
                {
                  el.created_by.id===sessionData.id && 
                  <>
                    <IconButton
                      onMouseEnter={()=>setButtonHovering(true)}
                      onMouseLeave={()=>setButtonHovering(false)}
                      data-test-classname="filter-list-item-edit"
                      onClick={(event)=>{
                        event.stopPropagation()
                        // onDelete(el)
                        setEditModalOpen(el)
                      }}
                    >
                      {getIconComponent({label:'edit',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                    <KTooltip title={"Bulk action"}>
                      <div 
                        style={{width:40,height:40}}
                        onMouseEnter={()=>setButtonHovering(true)}
                        onMouseLeave={()=>setButtonHovering(false)}
                      >
                        <IconButton
                          onClick={(event)=>{
                            event.stopPropagation()
                            onBulkActionFilter(el)
                          }} 
                          data-test-classname="filter-list-bulk-update"
                        >
                          {getIconComponent({label:'bulk_update',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      </div>
                    </KTooltip>
                    <KTooltip title={checkEmailConfigured()?"Create Alert":"Email integration needs to be configured to send Alerts. Contact your K administrator"}>
                      <div 
                        style={{width:40,height:40}}
                        onMouseEnter={()=>setButtonHovering(true)}
                        onMouseLeave={()=>setButtonHovering(false)}
                      >
                        <IconButton
                          onClick={(event)=>{
                            event.stopPropagation()
                            setAddAlertModalOpen({filter:el})
                          }} 
                          disabled={!checkEmailConfigured()}
                          data-test-classname="filter-list-add-alert"
                        >
                          {getIconComponent({label:'add_alert',size:24,colour:theme.palette.primaryText.light})}
                        </IconButton>
                      </div>
                    </KTooltip>
                    <IconButton
                      onMouseEnter={()=>setButtonHovering(true)}
                      onMouseLeave={()=>setButtonHovering(false)}
                      onClick={(event)=>{
                        event.stopPropagation()
                        // onDelete(el)
                        setDeleteModalOpen(el)
                      }} 
                      data-test-classname="filter-list-item-delete"
                    >
                      {getIconComponent({label:'delete',size:24,colour:theme.palette.primaryText.light})}
                    </IconButton>
                  </>
                }
              </div>
            </div>
           </ContextMenuTrigger>
           <ContextMenu id={el.id}>
            <CustomMenu
              item={el}
              actions={[
                'open_new_tab',
              ]}
              itemUrl={`/basic_search?filterListId=${el.id}`} 
            />
          </ContextMenu>
          </>
        ))
      }
      <div style={{marginTop:8}} ref={scrollRef}>
        {
          state.filterListLoading && 
          <CircularProgress color='secondary'/>
        }
      </div>
      <WarningConfirmModal
        description={
          <span>You are about to delete the <span style={{fontWeight:700}}>"{deleteModalOpen?.name}"</span> filter</span>
        }
        setModalOpen={setDeleteModalOpen}
        modalOpen={deleteModalOpen}
        onConfirm={()=>onDelete(deleteModalOpen)}
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        alertMessage={alertMessage}
        confirmText={'DELETE'}
        disableConfirmButton={loading}
      />
      <SaveFilterModal
        state={{}}
        modalOpen={editModalOpen}
        setModalOpen={setEditModalOpen}
        history={history}
        disableRedirect
        editedFilter={editModalOpen}
        onSaveComplete={filter=>{
          dispatch({
            type:'set_filter_list',
            filterList:{
              ...state.filterList,
              items:state.filterList.items.map(f=>{
                if(f.id===filter.id){
                  return filter
                }else{
                  return f
                }
              })
            }
          })
        }}
      />
      <AddAlertModal
        modalOpen={addAlertModalOpen}
        setModalOpen={setAddAlertModalOpen}
        history={history}
      />
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired
}

export default withTheme()(withStyles(styles)(Body));