import React, { useReducer, useEffect,useState } from 'react';
import { withTheme, withStyles } from '@material-ui/core/styles';
import DeadEnd from '../../components/Generic/Page/DeadEnd';
import ProfileHeader from '../../components/UI/ProfileHeader/ProfileHeader3';
import ProfileLayout from '../../components/UI/ProfileLayoutNew/ProfileLayoutNew';
import { LinearProgress,Typography } from '@material-ui/core';
import TabBar from '../../components/UI/TabBar/TabBar';
import Body from '../../components/ManualCollectionInstanceProfile/Body/Body';
import DeleteModal from '../../components/ManualCollectionInstanceProfile/DeleteModal/DeleteModal'
import useGetCerebrum from '../../hooks/useGetCerebrum';
import { handleShareClick,  getDispFields, mapObjectName, removeUrlQueryArg, collectionIds, getUserRoles, checkStewardedCount, checkOwnedCount, setInitialState, sendMessage } from '../../utilities';
import ProfileSideBar from '../../components/UI/ProfileSideBar/ProfileSideBar';
import ProfileButton from '../../components/UI/Buttons/ProfileButton'
import axiosCerebrum from '../../axios-cerebrum';
import axiosSolr from '../../axios-solr'
import {addHistory} from '../../HistoryManager'
import DropdownButton from '../../components/UI/Buttons/DropdownButton'
import FollowButton from '../../components/UI/Buttons/FollowButton'
import { getIconLabel } from '../../components/UI/SearchResults/utils'
import 'url-search-params-polyfill';
import { globalListenerRef } from '../../GlobalListenerRef';
import { checkCollectionEditable, checkCollectionLinkable, checkIsFollowed } from '../../permissionChecker';
import ParentEditor from '../../components/ManualCollectionInstanceProfile/ParentEditor/ParentEditor';
import { connect, useDispatch } from 'react-redux'
import * as actions from '../../store/actions/index';
import * as actionTypes from '../../store/actions/actionTypes';
import ProfileModalController from '../../components/UI/ProfileModalController/ProfileModalController';

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  appBar: {
    zIndex: 10,
    marginBottom: 12
  },
  icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit,
  },

  normalText:{
    color:theme.palette.primaryText.main,
  },
  description:{
    color:theme.palette.primaryText.light,
    wordBreak:'break-word',
    marginBottom:16,
    fontSize:13.75,
  },
  clickableText:{
    cursor:'pointer',
    textDecoration:'underline',
    color:theme.palette.hyperLink.main,
    fontSize:13.75,
    marginLeft:8
  },
});

const initialState = {
  tabState: 0,
  // basic table data
  basicData: null,
  hideEmptySections: localStorage.getItem('instanceHideEmptySections')==='true',
  insightsData: null,
  collectionData:null,
  collectionError:null,
  collectionLoading:null,
  linkedData:{},
  linkedTabState:0,
  noteTabState:0,
  verifiesTabState:0,
  notVerifiesTabState:0,
  noteDetailMap:{},
  tagsData: {items:[]},
  deletedItems:[],
  showDeletedItems:false,
  instanceDataPageMap:{},
  runningCode:'',
  lineageData:{},
  ///////////////
  // job type instance

  dataTabState:0,
  contentTabState:0,
  listData:{},
  listSearch:{},
  listSort: 'last_used_srt desc',
  memberSearch:'',
};

function reducer(state, action) {
  switch (action.type) {
    case 'set_link_object_data':
      return {
        ...state,
        linkObjectData:action.linkObjectData
      }
    case 'set_list_modal_open':{
      return {
        ...state,
        listModalOpen:action.listModalOpen
      }
    }
    case 'set_child_count':{
      return {
        ...state,
        childCount: action.childCount
      }
    }
    case 'set_tab_state':
      return {
        ...state,
        tabState: action.tabState,
      }
    case 'set_terms':
      return {
        ...state,
        terms:action.terms,
        termsLoading:action.termsLoading,
        termsError:action.termsError
      }
    case 'set_user_map':{
      return {
        ...state,
        userMap:action.userMap
      }
    }
    case 'set_collection_map':{
      return {
        ...state,
        collectionMap:action.collectionMap
      }
    }
    case 'set_map_show_deleted':{
      return {
        ...state,
        mapShowDeleted:action.mapShowDeleted  
      }
    }
    case 'set_basic_data':
      return {
        ...state,
        basicData: action.basicData,
        basicDataLoading:action.basicDataLoading,
        basicDataError:action.basicDataError
      }
    case 'set_domain_map_data':
      return {
        ...state,
        domainMapData:action.domainMapData,
        domainMapLoading:action.domainMapLoading,
        domainMapError:action.domainMapError
      }
    case 'set_hide_empty_sections':
      if(action.hideEmptySections){
        localStorage.setItem('instanceHideEmptySections',true)
      }else{
        localStorage.removeItem('instanceHideEmptySections')
      }
      return {
        ...state,
        hideEmptySections:action.hideEmptySections
      }
    case 'set_insights_data':
      return {
        ...state,
        insightsData: action.insightsData,
      }
    case 'set_collection_data':
      return {
        ...state,
        collectionData:action.collectionData,
        collectionError:action.collectionError,
        collectionLoading:action.collectionLoading
      }
    case 'set_parent_instance':
      return {
        ...state,
        parentInstanceData:action.parentInstanceData,
        parentInstanceLoading: action.parentInstanceLoading,
        parentInstanceError: action.parentInstanceError
      }
    case 'set_linked_data':
      return {
        ...state,linkedData:action.linkedData
      }
    case 'set_linked_tabs':
      return {
        ...state,
        linkedTabs:action.linkedTabs,
        linkedTabsLoading:action.linkedTabsLoading,
        linkedTabsError:action.linkedTabsError
      }
    case 'set_linked_tab_state':
      return {
        ...state,linkedTabState:action.linkedTabState
      }
    case 'set_map_data':
      return {
        ...state,mapData:action.mapData
      }
    case 'set_verifies_tab_state':
      return {
        ...state, verifiesTabState:action.verifiesTabState
      }
    case 'set_not_verifies_tab_state':
      return {
        ...state, notVerifiesTabState:action.notVerifiesTabState
      }
    case 'set_show_deleted_items':
      return {
        ...state, showDeletedItems:action.showDeletedItems
      }
    case 'set_delete_modal_open':
      return {
        ...state,deleteModalOpen:action.deleteModalOpen
      }
    case 'set_collection_instances':{
      return {
        ...state,
        collectionInstancesData:action.collectionInstancesData
      }
    }
    case 'set_deleted_items':{
      return {
        ...state,
        deletedItems:action.deletedItems
      }
    }
    case 'set_following':{
      return {
        ...state, following:action.following
      }
    }
    case 'set_notes':
      return {
        ...state,
        notesLoading:action.notesLoading,
        notesError:action.notesError,
        notes:action.notes
      }
    case 'set_survey_data':
      return {
        ...state,
        surveyData:action.surveyData,
        surveyLoading:action.surveyLoading,
        surveyError:action.surveyError
      }
    case 'set_note_tab_state':{
      return {
        ...state,
        noteTabState:action.noteTabState
      }
    }
    case 'set_lineage_data':
      return {
        ...state,
        lineageData:action.lineageData
      }
    case 'set_lineage_selected_item':
      return {
        ...state,
        lineageSelectedItem:action.lineageSelectedItem
      }
    case 'set_note_detail_map':{
      return {
        ...state,
        noteDetailMap:action.noteDetailMap
      }
    }
    case 'set_note_modal_open':{
      return {
        ...state,
        noteModalOpen:action.noteModalOpen
      }
    }
  case 'set_changes':
    return {
      ...state,
      changes:action.changes,
      changesLoading:action.changesLoading,
      changesError:action.changesError
    }
  case 'set_change_date':
    return {
      ...state,
      changeDate:action.changeDate
    }
  case 'set_change_type':
    return {
      ...state,
      changeType:action.changeType
    }
  case 'set_selected_change':
    return {
      ...state,
      selectedChange:action.selectedChange
    }
  case 'set_contributers_data':{
    return {
      ...state,
      contributersData:action.contributersData
    }
  }
  case 'set_suggested_link':{
    return {
      ...state,
      suggestedLink:action.suggestedLink,
      suggestedLinkLoading:action.suggestedLinkLoading,
      suggestedLinkError:action.suggestedLinkError,
      suggestedHighlight: action.suggestedHighlight
    }
  }
  case 'set_instance_data':
    return {
      ...state,
      instanceData:action.instanceData,
      instanceLoading:action.instanceLoading,
      instanceError:action.instanceError
    }
  case 'set_instance_data_page_map':{
    return {
      ...state,
      instanceDataPageMap:action.instanceDataPageMap
    }
  }
  case 'set_running_code':
    return {
      ...state,
      runningCode:action.runningCode
    }
  case 'set_show_governance_tab':
    return {
      ...state,
      showGovernanceTab:action.showGovernanceTab
    }
  ////////////////////////
  // job type instance 
  case 'set_data_tab_state':
    return {
      ...state,
      dataTabState:action.dataTabState
    }
  case 'set_content_tab_state':
    return {
      ...state,
      contentTabState:action.contentTabState
    }
  case 'set_list_sort':
    return {
      ...state,
      listSort:action.listSort
    }
  case 'set_list_search':
    return{
      ...state,
      listSearch:action.listSearch
    }
  case 'set_list_data':
    return {
      ...state,
      listData:action.listData
    }
  case 'set_data_tab_option':
    return {
      ...state,
      dataTabOption:action.dataTabOption,
      dataTabOptionLoading:action.dataTabOptionLoading,
      dataTabOptionError:action.dataTabOptionError
    }
  case 'set_content_tab_option':
    return {
      ...state,
      contentTabOption:action.contentTabOption,
      contentTabOptionLoading:action.contentTabOptionLoading,
      contentTabOptionError:action.contentTabOptionError
    }
  case "set_member_data":
    return {
      ...state,
      memberData: action.memberData,
      memberError: action.memberError,
      memberNum: action.memberNum
    }
  case 'set_member_search':
    return {
      ...state,
      memberSearch: action.memberSearch
    }
  default:
    throw new Error("Reducer action not supported.", action);
  }
}


const ManualCollectionProfile = props => {

  const {
    match,
    theme,
    history,
    classes,
    sessionData,
    cerebrumObject,
    pageCache,
    storePageCache,
  } = props;

  const [state, dispatch] = useReducer(reducer, setInitialState(pageCache,initialState));
  const [linkModalOpen, setLinkModalOpen] = useState(false);

  const reduxDispatch = useDispatch();

  useEffect(()=>{
    if(!state)return;
    storePageCache({cacheID:window.location.href,...state})
  },[state,storePageCache])

  const loadChildCount = data => {
    dispatch({type:'set_child_count',childCount:data.hierarchy_children?.length || 0})
  }

  const enrichData = (fullResponse) => {
    addHistory({url:window.location.pathname, title: getDispFields(fullResponse,'dispTitle'), subTitle:getDispFields(fullResponse,'dispSubtitle'),object:fullResponse,type:'profile'})
    if(fullResponse.parent.name==='Access Role'){
      axiosSolr
        .get(`/solr/search/select`,{params:{q:`id:${fullResponse.id}`,fl:'source_txt,source_type_txt'}})
        .then(response=>{
          let detail = response.data.response.docs[0] || {}
          dispatch({ type: 'set_basic_data', basicData: {...(state.basicData||{}),...detail,...fullResponse} })
        })
        .catch(error=>{
          console.log(error)
          dispatch({ type: 'set_basic_data', basicData: fullResponse})
        })
    }else{
      dispatch({ type: 'set_basic_data', basicData: fullResponse})
    }
    loadChildCount(fullResponse)
  }

  const fetchList = () => {
    dispatch({type:'set_basic_data',basicDataLoading:state.basicData?false:true,basicData:state.basicData});
    if(cerebrumObject && !state.basicData){
      enrichData(cerebrumObject)
      return;
    }
    axiosCerebrum
      .get(
        `/api/collectioninstances/${match.params.id.toLowerCase()}`
      )
      .then(cerebrumResponse=>{
        let fullResponse = cerebrumResponse.data;
        if(!fullResponse){
          dispatch({ type: 'set_basic_data', basicDataError: true})
          return;
        }
        enrichData(fullResponse)
      })
      .catch(error=>{
        console.log(error)
        dispatch({ type: 'set_basic_data', basicDataError: true})
      })
  }


  const getTabOptions = (data) => {
    let tabOptions = ['DETAILS', 'RELATED'];
    let canAddNote = ['CHANGE','DATA_GOVERNANCE','DATA_MANAGEMENT'].includes(data.parent.category)
    if(canAddNote){
      tabOptions = ['DETAILS','MAP','KNOWLEDGE','RELATED']
    }
    if(data.parent.category==='GLOSSARY'){
      tabOptions = ['DETAILS','RELATED','SUGGESTED']
    }
    if(['Verified Use Case'].includes(data.parent.name)){
      tabOptions = ['DETAILS','VERIFIED FOR','NOT APPROVED FOR USE']
    }
    if(data.parent.id===collectionIds.domain){
      tabOptions = ['DETAILS','MAP','RELATED']
    }
    if(state.showGovernanceTab && data.parent.category!=='GLOSSARY'){
      tabOptions.push('GOVERNANCE')
    }
    tabOptions.push('CHANGES')
    if(data.parent.id===collectionIds.jobType){
      tabOptions = ['DETAILS','DATA','CONTENT']
    }
    return tabOptions;
  }

  const checkShowGovernance = async () => {
    if(sessionData.user_role){
      let userRoles = getUserRoles(sessionData.user_role);
      if(userRoles.find(u=>['10','40'].includes(u))){
        dispatch({type:'set_show_governance_tab',showGovernanceTab:true});
        return;
      }
    }
    let stewardCount = await checkStewardedCount(sessionData)
    let ownerCount = await checkOwnedCount(sessionData)
    if(stewardCount.total>0 || ownerCount.total>0){
      dispatch({type:'set_show_governance_tab',showGovernanceTab:true});
      return;
    }
  }
  
  useEffect(()=>{
    if(!state.basicData)return;
    if(['LIST'].includes(state.basicData.parent.category))return;
    if(typeof(state.checkShowGovernanceTab)==='boolean')return;
    checkShowGovernance();
  // eslint-disable-next-line
  },[state.basicData])

  useEffect(()=>{
    const urlSearch = new URLSearchParams(window.location.search);
    const tabName = urlSearch.get('tabName');
    if(tabName && state.basicData){
      let tabOptions = getTabOptions(state.basicData);
      if(!tabOptions.includes(tabName.toUpperCase()))return;
      window.history.replaceState(null, null, removeUrlQueryArg({url:window.location.toString(),keys:['tabName']}));
      let presetTabState = tabOptions.indexOf(tabName.toUpperCase());
      dispatch({type:'set_tab_state',tabState:presetTabState})
    }
  // eslint-disable-next-line
  },[state.basicData, state.showGovernanceTab])


  const followDataFetch = () => {
    checkIsFollowed({objectId:match.params.id.toLowerCase()})
      .then(followed=>{
        dispatch({type:'set_following',following:followed})
      })
  }
  
  const {
    data:collectionData,
    loading:collectionLoading,
    error:collectionError,
    fetchList:collectionFetch
  } = useGetCerebrum({
    url:`/api/collections/${state.basicData?state.basicData.parent_id:''}`,
    preventAuto:true
  })


  useEffect(()=>{
    let data = collectionData || state.collectionData;
    dispatch({
      type:'set_collection_data',
      collectionData:data,collectionError,collectionLoading
    })
  // eslint-disable-next-line 
  },[collectionData,collectionLoading,collectionError])

  // load collection instance map
  useEffect(()=>{
    if(!state.collectionInstancesData?.items)return;
    if(!state.collectionInstancesData?.items.length===0)return;

    let collectionPropFilterIDs = [];
    let properties = state.collectionData.properties;

    properties.forEach(el=>{
      if(['COLLECTION_LOOKUP_FILTERED_MULTI'].includes(el.data_type)){
        collectionPropFilterIDs.push({filter:el.filter, propID:el.id, isMulti:true})
      }
      if(['COLLECTION_LOOKUP_FILTERED'].includes(el.data_type)){
        collectionPropFilterIDs.push({filter:el.filter, propID:el.id, isMulti:false})
      }
    })
    const onProcessCollectionMap = cis => {
      let map = {}
      let properties = {}
      collectionPropFilterIDs.forEach(p=>{
        properties[p.propID] = p.isMulti?[]:'';
      })
      cis.forEach(ci=>{
        map[ci.id] = ci;
        let matchedProp = collectionPropFilterIDs.find(c=>c.filter===ci.parent_id);
        if(!matchedProp)return;
        if(matchedProp.isMulti)properties[matchedProp.propID].push(ci.id) 
        else{properties[matchedProp.propID] = ci.id }
      })
      dispatch({type:'set_basic_data',basicData:{...state.basicData,properties:{...state.basicData.properties,...properties}}})
      dispatch({type:'set_collection_map',collectionMap:map});
    }

    if(collectionPropFilterIDs.length===0){
      dispatch({type:'set_collection_map',collectionMap:state.collectionMap || {}});
    }else{
      onProcessCollectionMap(state.collectionInstancesData.items)
    }
  // eslint-disable-next-line
  },[state.collectionInstancesData])

  //// load user map
  useEffect(()=>{
    if(!state.basicData||!state.collectionData)return;
    let properties = state.collectionData.properties;
    let values = state.basicData.properties;
    let userIds = [];
    properties.forEach(el=>{
      if(['USER_LOOKUP_FILTERED','USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP','USER_LOOKUP_MULTI'].includes(el.data_type)){
        if(values[el.id] && values[el.id]!==''){
          userIds = [...userIds,...(['USER_LOOKUP_FILTERED_MULTI','USER_LOOKUP_MULTI'].includes(el.data_type)?values[el.id]:[values[el.id]])];
          userIds = state.userMap?userIds.filter(id=>!state.userMap[id]):userIds
        }
      }
    })
    if(userIds.length===0){
      dispatch({type:'set_user_map',userMap:state.userMap || {}});
    }else{
      axiosSolr.get(
        '/solr/search/select',
        {params:{
          q:"*",
          fq:`id:(${userIds.join(' OR ')})`,
          fl:'name:name_txt,id',
          rows:userIds.length
        }}
      ).then(response=>{
        let map = {};
        response.data.response.docs.forEach(r=>{
          map[r.id]=r;
        })
        dispatch({type:'set_user_map',userMap:map});
      })
      .catch(error=>{
        console.log(error)
      })
    }
  // eslint-disable-next-line
  },[state.collectionData])

  useEffect(()=>{
    if(!state.basicData){
      fetchList();
    }
    if(state.followData===undefined){
      followDataFetch()
    }
  // eslint-disable-next-line
  },[])

  const generalLinkedFetch = (presetTabState) => {
    dispatch({
      type:'set_linked_tabs',
      linkedTabsLoading:true
    });
    dispatch({
      type:'set_linked_data',linkedData:{}
    });
    axiosCerebrum
      .get(`/api/collectioninstances/${match.params.id.toLowerCase()}/related/objects`,{params:{per_page:30,relationship:'MEMBERS,MEMBERS_AUTO,RELATED,RELATES_TO,MEMBER_OF,CLASSIFIES'}})
      .then(response=>{
        dispatch({type:'set_deleted_items',deletedItems:[]})
        let tabs = response.data.items.filter(el=>el.name!=='COLLECTION_INSTANCE').map(el=>({label:el.name,dispName:mapObjectName(el.name,true).replace(/_/g,' ')}));
        axiosCerebrum
        .get(
          `/api/collectioninstances/${match.params.id.toLowerCase()}/related`,
          {params:{relationship:'MEMBERS,MEMBERS_AUTO,RELATES_TO,MEMBER_OF,RELATED,CLASSIFIES',parent_flag:true,object_name:'COLLECTION_INSTANCE',per_page:100}}
        )
        .then(collectionResp=>{
          tabs = [...tabs, ...collectionResp.data.items.map(el=>({label:el.name,isCollection:true,dispName:el.name.toUpperCase()}))];
          if(presetTabState)dispatch({type:'set_linked_tab_state',linkedTabState:Math.max(0,Math.min(presetTabState,tabs.length-1))})
          else if(state.linkedTabState.length>=tabs.length)dispatch({type:'set_linked_tab_state',linkedTabState:tabs.length-1})
          dispatch({
            type:'set_linked_tabs',
            linkedTabs:tabs
          });
        })
        .catch(error=>{
          console.log(error)
          dispatch({
            type:'set_linked_tabs',
            linkedTabsError:true
          });
        })
       
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linked_tabs',
          linkedTabsError:true
        });
      })
  }

  const verifiedLinkedFetch = () => {
    dispatch({
      type:'set_linked_tabs',
      linkedTabsLoading:true
    });
    dispatch({
      type:'set_linked_data',linkedData:{
        verifies:{},
        notVerifies:{}
      }
    });
    let linkedTabs = {}
    axiosCerebrum
      .get(`/api/collectioninstances/${match.params.id.toLowerCase()}/related/objects`,{params:{per_page:30,relationship:'VERIFIES'}})
      .then(response=>{
        dispatch({type:'set_deleted_items',deletedItems:[]})
        let tabs = response.data.items.map(el=>({label:el.name,dispName:mapObjectName(el.name,true).replace(/_/g,' ')}));
        linkedTabs.verifies = tabs
        axiosCerebrum
          .get(`/api/collectioninstances/${match.params.id.toLowerCase()}/related/objects`,{params:{per_page:30,relationship:'NOT_VERIFIED_FOR'}})
          .then(response=>{
            let tabs = response.data.items.map(el=>({label:el.name,dispName:mapObjectName(el.name,true).replace(/_/g,' ')}));
            linkedTabs.notVerifies = tabs
            dispatch({
              type:'set_linked_tabs',
              linkedTabs
            });
          })
          .catch(error=>{
            console.log(error)
            dispatch({
              type:'set_linked_tabs',
              linkedTabsError:true
            });
          })
      })
      .catch(error=>{
        console.log(error)
        dispatch({
          type:'set_linked_tabs',
          linkedTabsError:true
        });
      })
  }

  const linkedFetch = (data,presetTabState) => {
    // dispatch({type:'set_map_data'})
    dispatch({type:'set_linked_tab_state',linkedTabState:0})
    if(state.basicData.parent.name==='Verified Use Case'){
      verifiedLinkedFetch()
    }else{
      generalLinkedFetch(presetTabState)
    }
  }

  useEffect(()=>{
    if(!state.basicData)return;
    if(!state.collectionData)collectionFetch();
    let data = state.basicData;
    if(!state.linkedTabs)linkedFetch(data)
   // eslint-disable-next-line
  },[state.basicData])

  const getLinkableObjects = () => {
    if(!state.basicData)return;
    let linkableObjects = ['TABLE','COLUMN','SCHEMA','DATABASE','MACRO','PROCEDURE','CONTENT','DATASET','DATASET_FIELD','DATASET_TABLE','CONTENT_CHILD','DATA_PIPELINE','FILE','CONTENT_APP','ML_MODEL'];
    if(state.basicData.parent && state.basicData.parent.id===collectionIds.dataRole){
      linkableObjects = ['USER']
    }
    if(state.basicData.parent && state.basicData.parent.id===collectionIds.piiDetected){
      linkableObjects = ['TABLE','COLUMN']
    }
    if(state.basicData.parent && state.basicData.parent.id===collectionIds.sourceLayer){
      linkableObjects.push('HOST','TOOL')
    }
    if(state.basicData.parent && state.basicData.parent.id===collectionIds.jobType){
      linkableObjects = ['USER']
    }
    return linkableObjects;
  }

  useEffect(()=>{
    window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    globalListenerRef.profileLinkModalListener = (msg) => {
      if(msg.data.reload_related_terms ){
        dispatch({type:'set_terms'})
        linkedFetch()
      }
      if(msg.data.open_link_term_modal){
        setLinkModalOpen({relations:['RELATED'],linkableObjects:['TERM'],onLinkUpdated:linkedFetch})
      }
      if(msg.data.open_instance_link_modal){
        setLinkModalOpen({relations:['MEMBERS'],linkableObjects:getLinkableObjects(),onLinkUpdated:()=>sendMessage({reload_member:true})})
      }
    }
    window.addEventListener('message',globalListenerRef.profileLinkModalListener)

    return ()=>{
      window.removeEventListener('message',globalListenerRef.profileLinkModalListener)
    }

  // eslint-disable-next-line
  },[state.basicData])

  if (state.basicDataLoading || collectionLoading) {
    return (
      <div style={{ textAlign:'center', width: '18.75rem',margin:'20vh auto'}}>
        <Typography className={classes.normalText}>Loading</Typography>
        <LinearProgress style={{ marginTop: '1.5rem' }} color="secondary" />
      </div>
    )
  }

  if (state.basicDataError || collectionError) {
    return (
      <DeadEnd />
    )
  }

  if(!state.basicData || !state.collectionData){
    return <div></div>
  }

  const onDeletInstance = () => {
    dispatch({type:'set_delete_modal_open',deleteModalOpen:true})
  }

  const setModifiedUser = () => {
    axiosCerebrum
      .put(
        `/api/collectioninstances/${state.basicData.id}/related?relationship=MODIFIED_BY&object_id=${sessionData.id}`
      )
      .then(response=>{
        window.postMessage({reload_sidebar:'managedBy'},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
      })
      .catch(error=>{
        console.log(error)
      })
    axiosCerebrum
      .put(`/api/me/follows/${match.params.id.toLowerCase()}?type=OPT_IN`)
      .then(()=>{
        dispatch({type:'set_following',following:true})
      })
      .catch(error=>{
        console.log(error)
      })
  }
  
  const onBulkAction = () => {
    let name = `Assets from ${state.basicData.parent?.category?.toLowerCase()?.replace(/_/g,' ')} instance: ${state.basicData.name}`
    if(state.basicData.parent?.category==='LIST'){
      name = `Assets from List: ${state.basicData.name}`
    }
    reduxDispatch({
      type:actionTypes.SET_BULK_EDIT_PARAM,
      data:{
        type:'solr',
        instanceId: state.basicData.id,
        redirectUrl:window.location.pathname,
        fileName:name
      }
    })
    
    history.push('/bulk_update')
  }


  let editable = checkCollectionEditable({sessionData, collectionData:state.basicData.parent});


  const isQuestion = state.basicData.parent.name==='Question';
  const isList = state.basicData.parent.name==='List';


  let buttons = [];

  buttons.push(
    <ProfileButton
      onClick={() => onBulkAction()}
      iconLabel='bulk_action'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
    />
  )

  if(editable){
    buttons.push(
      <ProfileButton
        onClick={() => onDeletInstance()}
        iconLabel='delete'
        iconColour={theme.palette.primaryText.light}
        iconOnly={true}
      />
    )
  }

  buttons.push(
    <ProfileButton
      onClick={() => handleShareClick()}
      iconLabel='share'
      iconColour={theme.palette.primaryText.light}
      iconOnly={true}
      tooltip={'Share link'}
    />
  )

  if(!isList){
    buttons.push(
      <ProfileButton
        onClick={() => dispatch({type:'set_list_modal_open',listModalOpen:true})}
        iconLabel='add_list'
        iconColour={theme.palette.primaryText.light}
        iconOnly={true}
        tooltip={'Add to List'}
      />
    )
  }

  let linkable = state.basicData.parent && 
                  state.basicData.parent.category!=='CHANGE'  && 
                  ![collectionIds.verifiedUseCase,collectionIds.domain,collectionIds.classification,collectionIds.accessRole].includes(state.basicData.parent.id) && 
                  checkCollectionLinkable({sessionData})

  if(linkable){
    if(state.basicData.parent?.id!==collectionIds.jobType){
      buttons.push(
        <ProfileButton
          onClick={() => setLinkModalOpen({
            relations:state.basicData.parent.category==='GLOSSARY'?['RELATES_TO']:['MEMBERS'],
            linkableObjects:getLinkableObjects(),
            onLinkUpdated:linkedFetch
          })}
          iconLabel='link'
          text='LINK'
        />
      )
    }
  }else if(![collectionIds.verifiedUseCase,collectionIds.domain,collectionIds.classification,collectionIds.accessRole].includes(state.basicData.parent.id)){
    buttons.push(
      <ProfileButton
        iconLabel='link'
        text='LINK'
        disabled
        tooltip={'Your role cannot link collections. Review your role permissions with your K admin'}
      />
    )
  }


  let canAddNote = ['CHANGE','DATA_GOVERNANCE','DATA_MANAGEMENT'].includes(state.basicData.parent.category)

  if(canAddNote){
    buttons.push(
      <DropdownButton
        iconLabel='add'
        text='ADD'
        optionList={[
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:true}),iconLabel:'note',text:'Add Knowledge'},
          {onClick:()=>dispatch({type:'set_note_modal_open',noteModalOpen:{isQuestion:true}}),iconLabel:'question',text:'Ask Question'},
        ]}
      />
    )
  }


  buttons.push(
    <FollowButton
      following={state.following}
      object={state.basicData}
      dispatch={dispatch}
    />
  )

  let title = state.basicData.name;


  let iconLabel = getIconLabel({label:'collection_instance',item:state.basicData})

  let bannerVisibility, bannerCause, bannerdisplayText;

  if(isQuestion && (!state.basicData.properties[3] || state.basicData.properties[3].trim()==='')){
    bannerdisplayText = "This question is unanswered."
    bannerCause = 'question_unanswered';
  }

  let sideBarMapping = ['tags','lastUpdatedBy','lastUpdated','createdOn']
  if(state.collectionData.category==='GLOSSARY')sideBarMapping.push('alias')
  if(state.collectionData.name==='Access Role')sideBarMapping.push('source')
  if(canAddNote)sideBarMapping.push('knowledge')
  if(!isList){
    sideBarMapping.push('collectionType','createdBy')
  }
  if(state.collectionData.id===collectionIds.domain || ['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.collectionData.category)){
    sideBarMapping.push('verified','verifiedNot','classification')
  }
  let showParent;
  if(['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.collectionData.category)){
    sideBarMapping.push('domain','instanceParent')
    showParent = true;
  }
  if(collectionIds.category===state.collectionData.id){
    sideBarMapping.push('instanceParent')
    showParent = true;
  }
  if(state.collectionData.id===collectionIds.verifiedUseCase){
    sideBarMapping.push('domain','classification')
  }
  if([collectionIds.domain, collectionIds.classification, collectionIds.dqDimensions, collectionIds.verifiedUseCase, collectionIds.dataRole, collectionIds.channel].includes(state.collectionData.id) || ['DATA_MANAGEMENT','DATA_GOVERNANCE','GLOSSARY'].includes(state.collectionData.category)){
    sideBarMapping.push('owner','stewardedBy')
  }
  if(isList)sideBarMapping.push('managedBy')
  
  return (
    <div>
      <ProfileModalController
        state={state}
        dispatch={dispatch}
        linkModalOpen={linkModalOpen}
        setLinkModalOpen={setLinkModalOpen}
        modalMapping={['list','link','note']}
        history={history}
      />
      {
        showParent && 
        <ParentEditor
          state={state}
          dispatch={dispatch}
          history={history}
          editable={editable}
        />
      }
      <DeleteModal
        state={state}
        history={history}
        modalOpen={state.deleteModalOpen}
        setModalOpen={open=>dispatch({type:'set_delete_modal_open',deleteModalOpen:open})}
      />
      <ProfileLayout
        header={(
          <ProfileHeader
            type='collection'
            title={title}
            shouldLoadBreadCrumb
            nodeKey={match.params.id.toLowerCase()}
            iconLabel={iconLabel}
            label={'collection_instance'}
            buttons={buttons}
            data={state.basicData}
            history={history}
            shouldLoadLinkedInstance
            state={state}
            dispatch={dispatch}
            onClickAddTag={()=>{
              setLinkModalOpen({
                linkableObjects:['COLLECTION_INSTANCE'],
                relations:['MEMBER_OF'],
                onLinkUpdated:()=>window.postMessage({reload_header_instance:true},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
              })
            }}
            bannerdisplayText={bannerdisplayText}
            bannerVisibility={bannerVisibility}
            bannerCause={bannerCause}
          />
        )}
        tabBar={
          isList?
          undefined
          :
          <TabBar
            tabOptions={getTabOptions(state.basicData)}
            tabState={state.tabState}
            setTabState={value => dispatch({ type: 'set_tab_state', tabState: value })}
            minWidth={220}
            maxWidth={220}
            disableUnderline={true}
          />
        }
        body={
          <Body
            history={history}
            basicData={state.basicData}
            state={state}
            dispatch={dispatch}
            fetchList={fetchList}
            sessionData={sessionData}
            nodeKey={match.params.id.toLowerCase()}
            linkedFetch={linkedFetch}
            setModifiedUser={setModifiedUser}
            tabOptions={getTabOptions(state.basicData)}
            editable={editable}
            setLinkModalOpen={setLinkModalOpen}
          />
        }
        sideBar={
          <ProfileSideBar
            tabOptions={getTabOptions(state.basicData)}
            history={history}
            mapping={sideBarMapping}
            state={state}
            dispatch={dispatch}
            cerebrumLabel='collectioninstances'
            basicData={state.basicData}
            sessionData={sessionData}
            fetchList={fetchList}
            data={state.basicData}
          />
        }
        disableMinContentHeight={['CHANGES'].includes(getTabOptions(state.basicData)[state.tabState])}
        hideSideBar={['MAP','CHANGES','RELATED'].includes(getTabOptions(state.basicData)[state.tabState])}
        state={state}
        dispatch={dispatch}
        label={state.basicData.labels}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    pageCache: state.pageCache.pageCache,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    storePageCache: (state) => dispatch(actions.storePageCache(state))
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(withTheme()(withStyles(styles)(ManualCollectionProfile)));