import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import ReviewProperties from './ReviewProperties/ReviewProperties';
import { getIconComponent } from '../../../utilities';
import { actionIds, getValidActions } from '../utils';
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import UpdatedItemsList from './UpdatedItemsList';

const styles = theme => ({
  root: {

  },
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 40,
  },
  expansionPanel: {
    transition: 'height 0.5s',
    marginBottom: 40,
  },
  expansionPanelHeader: {
    fontSize: 13.75,
    color: theme.palette.primary.main,
    marginBottom: 16,
    cursor: 'pointer',
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
  }
})

function PropertyDetails(props) {

  const {
    classes,
    theme,
    state,
    sessionData
    // dispatch,
    // history,
  } = props;

  const [expandStates, setExpandStates] = useState({
    bulkAction: true,
    bulkActionDetails: true,
    itemsToUpdate: true,
  });

  let detailComponent;

  if ([actionIds.bulkUpdateProperties, actionIds.linkToCollections, actionIds.createAndLinkToList, actionIds.linkToList].includes(state.selectedAction)) {
    detailComponent = <ReviewProperties {...props} />;
  }

  const getExpansionHeader = ({ text, id }) => {
    return (
      <Typography
        className={classes.expansionPanelHeader}
        onClick={() => setExpandStates({ ...expandStates, [id]: !expandStates[id] })}
      >
        {text}
        <span style={{ position: 'relative', bottom: -3, right: -6 }}>
          {getIconComponent({ label: expandStates[id] ? 'triangle_down' : 'triangle_up', size: 16, colour: theme.palette.primary.main })}
        </span>
      </Typography>
    )
  }

  let selectedAction = getValidActions({ sessionData }).find(o => o.id === state.selectedAction);

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>
        Review Bulk Action
      </Typography>
      <div
        className={classes.expansionPanel}
        style={{
          height: expandStates.bulkAction ? 'auto' : 20,
          overflow: 'hidden',
          maxWidth: 1000
        }}
      >
        {getExpansionHeader({ text: 'BULK ACTION', id: 'bulkAction' })}
        <SimpleResultItem
          title={selectedAction.title}
          titleColour={theme.palette.primaryText.main}
          subTitle={selectedAction.subTitle}
          key={selectedAction.id}
          hideIcon
          item={selectedAction}
          showUnderline
        />
      </div>

      <div
        className={classes.expansionPanel}
        style={{
          height: expandStates.bulkActionDetails ? 'auto' : 20,
          overflow: 'hidden',
          maxWidth: 1000
        }}
      >
        {getExpansionHeader({ text: 'BULK ACTION DETAILS', id: 'bulkActionDetails' })}
        {detailComponent}
      </div>

      <div
        className={classes.expansionPanel}
        style={{
          height: expandStates.itemsToUpdate ? 'auto' : 20,
          overflow: 'hidden',
        }}
      >
        {getExpansionHeader({ text: 'ITEMS TO UPDATE', id: 'itemsToUpdate' })}
        <UpdatedItemsList {...props} />
      </div>
    </div>
  )
}

PropertyDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(PropertyDetails));