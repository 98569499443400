import axiosCerebrum from "../../axios-cerebrum"
import { IntroManagerMain } from "../../components/UI/IntroManager/IntroManager"
import keycloakInstance from "../../keycloak-instance"
import { checkCanUploadBulkFile, checkIsAdmin, checkIsBusinessUserOnly, checkIsDataGov, checkIsDataManager, checkSingleSingOnEnabled } from "../../permissionChecker"
import { getCustomerName, getKnowledgeBaseUrl, getVersionNumber } from "../../utilities"
import Alert from 'react-s-alert'

export const adminPageInfo = {
  customisation:{
    name:'Customisation',
    description:'Customise K for your organisation',
    icon: 'customisation',
  },
  roles:{
    name: 'Roles',
    description: 'Modify the permissions for roles in K',
    icon: 'manage_role',
  },
  users:{
    name: 'Users',
    description: 'Manage users onboarded to K',
    icon: 'user',
  },
  teams:{
    name: 'Teams',
    description: 'Add, update and delete teams',
    icon: 'accountTeams',
  },
  merge:{
    name:'Merge',
    description:'Merge duplicate items found in the ecosystem',
    icon:'merge',
  },
  alerts:{
    name:'Alerts',
    description:'Manage all alerts',
    icon:'alert',
  },
  collections:{
    name:'Collections',
    description:'Add, manage and remove collections',
    icon:'collection',
  },
  linkingRules:{
    name:'Linking rules',
    description:'Manage Collection linking rules',
    icon:'instance_linking',
  },
  sources:{
    name:'Sources',
    description:'Connect and schedule Sources',
    icon: 'source',
  },
  integrations:{
    name:'Integrations',
    description:'Connect 3rd party tools to K',
    icon: 'integration',
  },
  accessKeys:{
    name:'Access Keys',
    description:'Add, manage, and remove access keys for your integrations',
    icon: 'access_key',
  },
  monitor:{
    name:'Monitor',
    description:'See the status of platform jobs',
    icon:'monitor'
  },
  batchManager:{
    name:'Batch Manager',
    description:'Run manual load and platform jobs',
    icon:'batch_manager'
  },
  settings:{
    name:'Settings',
    description:'Configure platform settings',
    icon:'settingFilled'
  }
}

export const getListItemJson = roles => {
  const userManagement = [
    {
      title: adminPageInfo.customisation.name,
      description: adminPageInfo.customisation.description,
      route: '/admin/k_platform_setup',
      icon: adminPageInfo.customisation.icon,
      roles:['00','10']
    },
    roles.find(el=>['00','10'].includes(el))?
    undefined:
    {
      title: 'Data Governance Settings',
      description: 'Customise K for your organisation',
      route: '/admin/k_platform_setup',
      icon: 'customisation',
      roles:['40']
    },
    {
      title:adminPageInfo.roles.name,
      description:adminPageInfo.roles.description,
      route: '/admin/manage_role',
      icon: adminPageInfo.roles.icon,
      roles:['00']
    },
    {
      title: adminPageInfo.users.name,
      description: adminPageInfo.users.description,
      route: '/admin/users',
      icon: adminPageInfo.users.icon,
      roles:['00','10']
    },
    {
      title: adminPageInfo.teams.name,
      description: adminPageInfo.teams.description,
      route: '/admin/teams',
      icon: adminPageInfo.teams.icon,
      roles:['00','10']
    },
    {
      title:  adminPageInfo.merge.name,
      description: adminPageInfo.merge.description,
      route: '/admin/merge',
      icon: adminPageInfo.merge.icon,
      roles:['00','10']
    },
    {
      title:  adminPageInfo.alerts.name,
      description: adminPageInfo.alerts.description,
      route: '/admin/alerts',
      icon: adminPageInfo.alerts.icon,
      roles:['00']
    },
  ]

  const Collections = [
    {
      title: adminPageInfo.collections.name,
      description: adminPageInfo.collections.description,
      route: '/admin/collection',
      icon: adminPageInfo.collections.icon,
      roles:['00','10','40']
    },
    {
      title:  adminPageInfo.linkingRules.name,
      description: adminPageInfo.linkingRules.description,
      route: '/admin/instance_linking',
      icon: adminPageInfo.linkingRules.icon,
      roles:['00','10','40']
    },
  ]

  const integrations = [
    {
      title: adminPageInfo.sources.name,
      description: adminPageInfo.sources.description,
      route: '/admin/sources',
      icon: adminPageInfo.sources.icon,
      roles:['00']
    },
    {
      title: adminPageInfo.integrations.name,
      description: adminPageInfo.integrations.description,
      route: '/admin/integrations',
      icon: adminPageInfo.integrations.icon,
      roles:['00','10']
    },
    {
      title:  adminPageInfo.accessKeys.name,
      description:  adminPageInfo.accessKeys.description,
      route: '/admin/access_key',
      icon:  adminPageInfo.accessKeys.icon,
      roles:['00','10']
    },
  ]

  const platformSettings = [
    {
      title: adminPageInfo.monitor.name,
      description: adminPageInfo.monitor.description,
      route: {
        pathname: '/admin/platform_management',
        state: {
          tabState: 0,
        }
      },
      icon: adminPageInfo.monitor.icon,
    },
    {
      title: adminPageInfo.batchManager.name,
      description:adminPageInfo.batchManager.description,
      route: {
        pathname: '/admin/platform_management',
        state: {
          tabState: 1,
        }
      },
      icon: adminPageInfo.batchManager.icon,
    },
    {
      title: adminPageInfo.settings.name,
      description: adminPageInfo.settings.description,
      route:
      {
        pathname: '/admin/platform_management',
        state: {
          tabState: 2,
        }
      },
      icon: adminPageInfo.settings.icon,
    }
  ]

  return {userManagement, Collections, integrations, platformSettings}
}


export const getInsightsListMap = ({hasDQ, roles, isSteward, isOwner, isDataGov, ownOrStewardDomain}) => {
  let listMap = {}
  let usageArr = [];
  if(roles.includes('10')){
    usageArr.push(
      {
        roles:['10'],
        icon:'insights',
        route:'/insights/usage',
        title:'Usage Insights',
        description:'Understand which data assets are regularly used and by whom',
        testID:'nav-bar-usage-insights'
      }
    )
    usageArr.push(
      {
        roles:['10'],
        icon:'complexity',
        route:'/insights/complexity',
        title:'Complexity Insights',
        description:'Understand how your data assets are connected',
        testID:'nav-bar-complexity-insights'
      }
    )
  }
  if(usageArr.length>0){
    listMap.MANAGEMENT = usageArr;
  }

  let govArr = [];
  if(['10','40'].find(el=>roles.includes(el)) ){
    govArr.push(
      {
        roles:['10','40'],
        icon:'governance',
        route:'/insights/governance',
        title:'Governance Insights',
        description:'Understand the progress of your governance documentation',
        testID:'nav-bar-governance-insights'
      }
    )
  }
  if(hasDQ && (['10','40'].find(el=>roles.includes(el)) || ownOrStewardDomain)){
    govArr.push(
      {
        icon:'data_quality_test',
        route:'/insights/data_quality',
        title:'Data Quality Insights',
        description:'Understand how your data ecosystem is performing against 6 key Data Quality measures',
        testID:'nav-bar-dq-insights'
      }
    )
  }
  if(['10','40'].find(el=>roles.includes(el)) ){
    govArr.push(
      {
        roles:['10','40'],
        icon:'issue_dashboard',
        route:'/dashboard/issue',
        title:'Issue Dashboard',
        description:'Understand all data Issues that have been rasied or linked to the K platform',
        testID:'nav-bar-issue-dashboard'
      }
    )
  }
  if(isOwner || isDataGov){
    govArr.push(
      {
        icon:'dashboard',
        route:'/dashboard/data_owner',
        title:'Owner Dashboard',
        description:'A holistic dashboard showing all data, content and collections that is owned by a Data Owner ',
        testID:'nav-bar-owner-dashboard'
      }
    )
  }
  if(isSteward || isDataGov){
    govArr.push(
      {
        icon:'dashboard',
        route:'/dashboard/data_steward',
        title:'Steward Dashboard',
        description:'A holisitic dashboard showing all data, content and collections that is managed by a Data Steward ',
        testID:'nav-bar-steward-dashboard'
      }
    )
  }
  if(['10','40'].find(el=>roles.includes(el)) && localStorage.hasOwnProperty('demo') ){
    govArr.push(
      {
        roles:['10','40'],
        icon:'insights',
        route:'/insights/data_risk',
        title:'Sensitive Data Insights',
        description:'Understand your data sensitivity & controls',
        testID:'nav-bar-data-risk-insights'
      }
    )
    govArr.push(
      {
        roles:['10','40'],
        icon:'classification',
        route:'/insights/data_classification',
        title:'Data Classification Insights',
        description:'Understand how your classified data is used',
        testID:'nav-bar-data-classification-insights'
      }
    )
    govArr.push(
      {
        roles:['10','40'],
        icon:'insights',
        route:'/insights/user_access',
        title:'Data User & Access Insights',
        description:'Understand data user & access risks',
        testID:'nav-bar-data-data-user-insights'
      }
    )
  }
  if(govArr.length>0){
    listMap.GOVERNANCE = govArr
  }

  let adminArr = []
  if(['00','10'].find(el=>roles.includes(el))){
    adminArr.push(
      {
        roles:['00','10'],
        icon:'data_load',
        route:'/dashboard/data_load',
        title:'Data Load Dashboard',
        description:'Understand the status, schedule and progress of all data jobs connected to the K Platform',
        testID:"nav-bar-data-load-dashboard"
      }
    )
  }
  if(adminArr.length>0){
    listMap.ADMINISTRATION = adminArr;
  }

  return listMap

}

export const getUserSettingList = ({history, sessionData}) => {
  let listMap = {};

  let myProfileArr = [
    {
      icon:'setting',
      route:'/settings',
      title:'Profile Settings',
      description:'Customise your own personal preferences',
      testID:'nav-bar-profile-setting'
    }
  ];

  if(!checkSingleSingOnEnabled()){
    myProfileArr.push({
      icon:'password',
      route:document.location.protocol + "//" + document.location.hostname + '/keycloak/auth/realms/kada/account/',
      isNewWindow:true,
      title:'Password update',
      description:'Update your password for K',
      testID:'nav-bar-password-update'
    })
  }
  
  let customerName = getCustomerName()
  let knowledgeBaseUrl = getKnowledgeBaseUrl()

  let learningOptArr = [
    {
      icon:'tutorial',
      onClick:()=>{
        localStorage.removeItem("intro_finished"); 
        IntroManagerMain(history, sessionData)
      },
      title:'Tutorial',
      description:'Re-visit the new user guided tour of the K Platform',
      testID:'nav-bar-tutorial'
    },
    {
      icon:'knowledge_base',
      onClick:()=>{
        window.open('https://kada-knowledge-library.scrollhelp.site/home/','_blank')
      },
      title:'K Knowledge Base',
      description:'Access helpful tips, user guides and read about K’s key features',
      testID:'nav-bar-knowledge-base'
    }
  ]

  if(customerName && knowledgeBaseUrl){
    learningOptArr.push({
      icon:'knowledge_base',
      onClick:()=>{
        window.open(knowledgeBaseUrl,'_blank')
      },
      title:`${customerName} Knowledge Base`,
      description:`Access ${customerName}'s internal knowledge base`,
      testID:'nav-bar-internal-knowledge-base'
    })
  }

  let aboutKArr = [
    {
      icon:'info_contained',
      onClick:()=>{
        window.open('https://kada-knowledge-library.scrollhelp.site/home/kada-software-license-agreement','_blank')
      },
      title:'Terms of Use',
      description:'Read the K Platform’s Terms of Use',
      testID:'nav-bar-terms'
    },
    {
      icon:'info_contained',
      onClick:()=>{
        window.open('https://kada-knowledge-library.scrollhelp.site/home/privacy-policy','_blank')
      },
      title:'Privacy Policy',
      description:'Access KADA’s Privacy Policy',
      testID:'nav-bar-policy'
    },
    {
      icon:'info_contained',
      onClick:()=>{
        window.open('https://docs.kada.ai/home/release-versions','_blank')
      },
      title:'Version ' + getVersionNumber(),
      description:'Learn about the features in this release',
      testID:'nav-bar-version'
    }
  ]

  listMap['MY PROFILE'] = myProfileArr;
  listMap['LEARNING OPTIONS'] = learningOptArr;
  listMap['ABOUT K'] = aboutKArr;
  
  return listMap
}

export const logoutItemConfig = 
  {
    icon:'log_out',
    onClick:()=>{
      keycloakInstance.clearToken();
      localStorage.removeItem('dark');
      window.open(keycloakInstance.createLogoutUrl(), "_self");
    },
    title:'Log out',
    description:'Log out of the K Platform',
    testID:'nav-bar-log-out'
  }

export const getEcosystemListMap = () => {
  let listMap = {}

  let ecosystemArr = [
    {
      icon:'config_circle',
      route:'/my_ecosystem?tabName=SOURCES',
      title:'Sources',
      description:'View all your data, content and reference sources linked to K',
      testID:'nav-bar-source'
    },
    {
      icon:'map',
      route:'/my_ecosystem?tabName=MAP',
      title:'Map',
      description:'Navigate through your sources, tools and reporting platforms in an interactive map',
      testID:'nav-bar-map'
    },
    {
      icon:'domain',
      route:`/my_ecosystem?tabName=DOMAIN MAP`,
      title:'Domain Map',
      description:'View your data domain structure',
      testID:'nav-bar-domain-map'
    },
    {
      icon:'search',
      route:'/my_ecosystem?tabName=DISCOVER',
      title:'Discover',
      description:'Understand new reports and items added to a range of collections',
      testID:'nav-bar-discover'
    }
  ];

  if(localStorage.hasOwnProperty('demo')){
    ecosystemArr.push({
      icon:'data_product',
      route:'/data_products',
      title:'Data Products',
      description:'Explore all the data products available in K.',
      testID:'nav-bar-data-products'
    })
  }

  listMap.ECOSYSTEM = ecosystemArr;

  return listMap
}


export const getDataAppListMap = ({sessionData, sendAlert}) => {
  let listMap = {}

  let appArr = [];

  if(!checkIsBusinessUserOnly({sessionData})){
    appArr.push(
      {
        icon:'ia_downstream',
        route:'/impact_assessment',
        title:'Impact Assessment',
        description:'Analyse the downstream data, content and user impacts for an asset',
        testID:'nav-bar-ia'
      },
      {
        icon:'ia_upstream',
        route:'/dependency_assessment',
        title:'Dependency Assessment',
        description:'Analyse the upstream dependencies for a data or content asset',
        testID:'nav-bar-da'
      }
    )
  }

  if(checkIsAdmin({sessionData}) || checkIsDataGov({sessionData}) || checkIsDataManager({sessionData})){
    appArr.push({
      icon:'bulk_update_lineage',
      route:'/bulk_update_lineage',
      title:'Bulk Update Lineage',
      description:'Bulk update manual lineage by using data rules to connect data and content',
      testID:'nav-bar-bulk-lineage'
    })
  }


  appArr.push(
    {
      icon:'code_search',
      route:'/code_search',
      title:'Code Search',
      description:'Search code used in macros, measures, procedures and queries',
      testID:'nav-bar-code-search'
    }
  )

  if(checkIsAdmin({sessionData}) || checkIsDataGov({sessionData}) || checkIsDataManager({sessionData})){
    appArr.push({
      icon:'questionboard',
      route:'/question_board',
      title:'Ask K',
      description:'Extract key metrics from K to analyse further',
      testID:'nav-bar-ask-k'
    })
  }

  if(checkCanUploadBulkFile({sessionData}) ){
    appArr.push({
      icon:'bulk_edit',
      route:'/bulk_edit_templated',
      title:'Import Bulk Edit File',
      description:'Bulk import metadata updates via a K generated excel template',
      testID:'nav-bar-import-meta-data'
    })
  }


  if(localStorage.hasOwnProperty('demo')){
    appArr.push(
      {
        icon:'refresh',
        title:'Demo Refresh',
        description:'Refresh demo data',
        testID:'nav-bar-demo-refresh',
        onClick:()=>{
          axiosCerebrum
            .post(
              '/api/platformbatches/0',
              {
                "adhoc_args":{
                  "job": "_9999_demo_refresh",
                }
              }
            )
            .then(()=>{
              Alert.info(`Demo data refreshed successfully`, {
                position: 'top',
                effect: 'stackslide',
              });
            })
            .catch(error=>{
              sendAlert({type:'error',message:"Error occurred refreshing data"})
              console.log(error);
            })
        }}
    )
  }

  listMap.APPLICATIONS = appArr;

  let resultsArr = [
    appArr.find(el=>el.title==='Impact Assessment')?{
      icon:'ia_downstream',
      route:'/my_tasks?tabName=EXTRACTS&jobName=IMPACT ASSESSMENT',
      title:'Recent Impact Assessments',
      description:'View all of your recent Impact Assessments',
      testID:'nav-bar-ia-results'
    }:undefined,
    appArr.find(el=>el.title==='Dependency Assessment')?{
      icon:'ia_upstream',
      route:'/my_tasks?tabName=EXTRACTS&jobName=DEPENDENCY ASSESSMENT',
      title:'Recent Dependency Assessments',
      description:'View all of your recent Dependency Assessments',
      testID:'nav-bar-da-results'
    }:undefined,
    appArr.find(el=>el.title==='Bulk Update Lineage')?{
      icon:'bulk_update_lineage',
      route:'/my_tasks?tabName=JOBS&jobName=BULK UPDATE LINEAGE',
      title:'Recent Bulk Lineage Updates',
      description:'View all of your recent Bulk Lineage Updates',
      testID:'nav-bar-bulk-lineage-results'
    }:undefined,
    appArr.find(el=>el.title==='Code Search')?{
      icon:'code_search',
      route:'/my_tasks?tabName=EXTRACTS&jobName=CODE SEARCH',
      title:'Recent Code Searches',
      description:'View all of your recent Code Searches',
      testID:'nav-bar-code-search-results'
    }:undefined,
    appArr.find(el=>el.title==='Ask K')?{
      icon:'questionboard',
      route:'/my_tasks?tabName=EXTRACTS&jobName=ASK K',
      title:'Recent Ask K extracts',
      description:'View all of your recent Ask K extracts',
      testID:'nav-bar-ask-k-results'
    }:undefined,
    appArr.find(el=>el.title==='Import Bulk Edit File')?{
      icon:'bulk_edit',
      route:'/my_tasks?tabName=JOBS&jobName=BULK EDIT VIA EXCEL',
      title:'Recent Bulk Edit imports',
      description:'View all of your recent Bulk Edit imports',
      testID:'nav-bar-bulk-edit-results'
    }:undefined,
  ];

  listMap['MY RESULTS'] = resultsArr.filter(el=>el);

  return listMap
}