import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography, Button } from '@material-ui/core';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import useAlert from '../../../../hooks/useAlert';
import PropertyItem from './PropertyItem';
import { collectionIds } from '../../../../utilities';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 16,
  },
  suggestionText: {
    fontSize: 12,
    color: theme.palette.primaryText.light,
    marginRight: 8,
    marginBottom: 8,
  },
  suggestionChip: {
    fontSize: 12,
    height: 24,
    padding: '0 8px',
    marginBottom: 8,
    marginRight: 8,
    borderRadius: 12,
    backgroundColor: theme.palette.chip.main,
    border: `1px solid ${theme.palette.chipBorder.main}`,
    color: theme.palette.primaryText.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  addSuggestionButton: {
    '& span': {
      fontSize: 12,
    },
    height: 24,
    marginBottom: 8
  }
})

function UpdateProperties(props) {

  const {
    classes,
    state,
    dispatch,
    // history,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const properties = state.properties;
  const setProperties = (properties) => dispatch({ type: 'set_properties', properties });

  const {
    sendAlert
  } = useAlert({})


  const getInitialvalues = (property) => {
    let id = property.id;
    let field = 'MEMBER_OF';
    let values = [];
    let valueDetails = [];
    let operation = 'ADD_TO_EXISTING';

    if (id === 'description') {
      field = 'DESCRIPTION';
      operation = 'REPLACE';
      values = [''];
    }
    else if (id === collectionIds.category) {
      field = 'MEMBER_OF';
      operation = 'REPLACE';
    }
    else if (id === collectionIds.classification) {
      field = 'CLASSIFIED_BY';
      operation = 'REPLACE';
    }
    else if (id === collectionIds.domain) {
      field = 'MEMBER_OF';
      operation = 'ADD_TO_EXISTING';
    }
    else if (id === 'verified_for') {
      field = 'VERIFIED_BY';
      operation = 'REPLACE';
    }
    else if (id === 'not_verified_for') {
      field = 'NOT_VERIFIED_BY';
      operation = 'REPLACE';
    }
    else if (id === 'owner') {
      field = 'OWNED_BY';
      operation = 'ADD_TO_EXISTING';
    }
    else if (id === 'steward') {
      field = 'STEWARDED_BY';
      operation = 'ADD_TO_EXISTING';
    }

    return {
      field,
      operation,
      values,
      valueDetails
    }
  }

  const onAddProperty = newProps => {
    let newProperties = [...properties, ...newProps];
    if (newProperties.length > 15) {
      sendAlert({ message: "You can only select max 15 properties to edit", type: 'error' })
      newProperties = newProperties.slice(0, 15)
    }
    let newPropertyValues = { ...state.propertyValues };
    newProperties.forEach(p => {
      if (!newPropertyValues[p.id]) {
        newPropertyValues[p.id] = getInitialvalues(p)
      }
    })
    dispatch({
      type: 'set_property_values',
      propertyValues: newPropertyValues
    })
    setProperties(newProperties)
  }

  const onRemoveProperty = id => {
    let newProperties = properties.filter(p => p.id !== id)
    let newPropertyValues = { ...state.propertyValues };
    delete newPropertyValues[id];
    dispatch({
      type: 'set_property_values',
      propertyValues: newPropertyValues
    })
    setProperties(newProperties)
  }

  const suggestions = [
    { name_txt: 'Description', id: 'description' },
    { name_txt: 'Categories', id: collectionIds.category },
    { name_txt: 'Classification', id: collectionIds.classification },
    { name_txt: 'Domains', id: collectionIds.domain },
    { name_txt: 'Verified for', id: 'verified_for' },
    { name_txt: 'Not Verified for', id: 'not_verified_for' },
    { name_txt: 'Owner', id: 'owner' },
    { name_txt: 'Stewards', id: 'steward' }
  ]

  return (
    <div>
      <Typography className={classes.header}>
        Update Properties
      </Typography>
      <div style={{ maxWidth: 800 }}>
        <SearchSelector
          url='/solr/search/select'
          testID={'add-property-search'}
          params={{
            q: `${searchValue}*`,
            fq: `object_type_srt:COLLECTION AND collection_type_srt:(GLOSSARY OR DATA_MANAGEMENT OR DATA_GOVERNANCE OR COLLABORATION)` +
              `${properties.length > 0 ? ` AND -id:(${properties.map(p => p.id).join(' ')})` : ''}`,
            fl: '*',
            sort: 'name_srt asc',
            rows: 10
          }}
          autoSuggestion
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={'Search for a property'}
          onResultClick={el => {
            onAddProperty([el])
          }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: 8, marginBottom: 40 }}>
        <Typography className={classes.suggestionText}>Suggested properties: </Typography>
        {
          suggestions.filter(s => !properties.find(p => p.id === s.id)).map(s => {
            return (
              <div
                className={classes.suggestionChip}
                key={s.id}
                onClick={() => {
                  onAddProperty([s])
                }}
              >
                {s.name_txt}
              </div>
            )
          })
        }
        <Button
          color='secondary'
          className={classes.addSuggestionButton}
          onClick={() => {
            onAddProperty(suggestions.filter(s => !properties.find(p => p.id === s.id)))
          }}
        >
          ADD ALL SUGGESTED
        </Button>
      </div>
      <>
        {
          properties.length === 0 &&
          <Typography>
            No properties selected
          </Typography>
        }
        {
          properties.map(p => {
            return (
              <PropertyItem
                property={p}
                key={p.id}
                state={state}
                dispatch={dispatch}
                onRemoveProperty={onRemoveProperty}
              />
            )
          })
        }
      </>
    </div>
  )
}

UpdateProperties.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(UpdateProperties));