import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Button, } from '@material-ui/core';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import useAlert from '../../../hooks/useAlert';
import ItemSelect from '../ItemSelect/ItemSelect';
import LinkingRulesSelect from '../LinkingRulesSelect/LinkingRulesSelect';
import axiosCerebrum from '../../../axios-cerebrum';

const styles = theme => ({
  root: {
    width: '100%',
  },
  labelText: {
    color: theme.palette.primaryText.light
  },
  selectedLabelText: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: '400 !important'
  },
  stepIcon: {
    cursor: 'pointer'
  },
  buttons: {
    position: 'fixed',
    justifyContent: 'flex-end',
    backgroundColor: theme.palette.background.main,
    height: 80,
    bottom: 0,
    right: 0,
    left: 40,
    display: 'flex',
    alignItems: 'center',
    borderTop: `1px solid ${theme.palette.listItemDivider.main}`
  },
  step: {
    '& svg': {
      color: theme.palette.primaryText.light
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  stepper: {
    backgroundColor: theme.palette.background.main
  },
  normalText: {
    color: theme.palette.primaryText.main
  }
})

function Body(props) {

  const {
    classes,
    state,
    dispatch,
    history,
  } = props;

  const [submittingJob, setSubmittingJob] = useState(false)

  const isCancelledRef = useRef(false)

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  window.onkeydown = function (event) {
    if (event.keyCode === 13) {
      if(stepClickable(state.activeStep + 1) && !submittingJob) {
        onNextClick();
      }
    }
  }

  useEffect(() => {
    return () => {
      isCancelledRef.current = true;
      window.onkeydown = null;
    }
  }, [])


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [state.activeStep])

  const steps = [
    'Select items to link',
    'Select linking rules'
  ]

  let bodyContent;

  if (state.activeStep === 0) {
    bodyContent = <ItemSelect state={state} dispatch={dispatch} />
  }
  if (state.activeStep === 1) {
    bodyContent = <LinkingRulesSelect state={state} dispatch={dispatch} />
  }

  const onConfirm = () => {
    setSubmittingJob(true)

    sendAlert({type:'info', message: 'Submitting the job. Please wait...'})

    let isExactMatch = !state.upstreamMatchConfig && !state.downstreamMatchConfig;
    let upstreamConfig, downstreamConfig;
    if(!isExactMatch){
      upstreamConfig = state.upstreamMatchConfig || {}
      downstreamConfig = state.downstreamMatchConfig || {}
    }

    let isChildExactMatch = !state.childrenUpstreamMatchConfig && !state.childrenDownstreamMatchConfig;
    let childrenUpstreamConfig, childrenDownstreamConfig;
    if(!isChildExactMatch){
      childrenUpstreamConfig = state.childrenUpstreamMatchConfig || {}
      childrenDownstreamConfig = state.childrenDownstreamMatchConfig || {}
    }

    let payload = {
      "job": "_0010_auto_mapping",
      "upstream_object_id": state.upstreamParentObject.id,
      "upstream_object_type": state.upstreamParentType,
      "downstream_object_id": state.downstreamParentObject.id,
      "downstream_object_type": state.downstreamParentType,
      "exact_match": isExactMatch,
      "upstream_match_config": upstreamConfig,
      "downstream_match_config": downstreamConfig,
      "children_exact_match": state.isMapChild ? isChildExactMatch : null,
      "children_upstream_match_config": childrenUpstreamConfig,
      "children_downstream_match_config": childrenDownstreamConfig
    }
    
    Object.keys(payload).forEach(key => payload[key] === null && delete payload[key]);

    axiosCerebrum
      .post(
        '/api/platformbatches/7',
        {
          "adhoc_args": payload
        }
      )
      .then(response => {
        setSubmittingJob(false)
        history.push(`/my_tasks?tabName=JOBS&jobName=BULK UPDATE LINEAGE`)
      })
      .catch(err => {
        setSubmittingJob(false)
        console.log(err)
        sendAlert({type:'error', message: 'Error occurred submitting the job. Please try again later.'})
      })

  }

  const onNextClick = () => {
    if (state.activeStep !== steps.length - 1) {
      dispatch({ type: 'set_active_step', activeStep: state.activeStep + 1 })
      return;
    }
    onConfirm();
  }

  const onBackClick = () => {
    dispatch({ type: 'set_active_step', activeStep: state.activeStep - 1 })
  }

  const isLinkingRuleFilled = () => {
    if (!state.upstreamParentObject || !state.downstreamParentObject) return false;
    if (state.upstreamMatchConfig) {
      if (Object.keys(state.upstreamMatchConfig).some(k => !state.upstreamMatchConfig[k])) return false;
    }
    if (state.downstreamMatchConfig) {
      if (Object.keys(state.downstreamMatchConfig).some(k => !state.downstreamMatchConfig[k])) return false;
    }
    if (state.childrenUpstreamMatchConfig) {
      if (Object.keys(state.childrenUpstreamMatchConfig).some(k => !state.childrenUpstreamMatchConfig[k])) return false;
    }
    if (state.childrenDownstreamMatchConfig) {
      if (Object.keys(state.childrenDownstreamMatchConfig).some(k => !state.childrenDownstreamMatchConfig[k])) return false;
    }
    return true;
  }

  const stepClickable = (step) => {
    if (step === 0) return true;
    if (step === 1) return state.upstreamParentType && state.downstreamParentType && state.upstreamSource && state.downstreamSource && typeof state.isMapChild === 'boolean'
    if (step === 2) return isLinkingRuleFilled() && stepClickable(1);
  }

  const onCancelClick = () => {
    let redirectUrl;
    if (!redirectUrl) {
      history.push('/home')
    };
    history.push(redirectUrl)
  }

  const onStepLabelClick = step => {
    if (stepClickable(step)) dispatch({ type: 'set_active_step', activeStep: step })
  }

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} activeStep={state.activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step className={classes.step} key={label} completed={false}>
            <StepLabel
              data-test-id={`step-label-${index}`}
              onClick={() => onStepLabelClick(index)}
              classes={{
                active: classes.selectedLabelText,
                iconContainer: classes.stepIcon,
                label: classes.labelText
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>

      <div style={{ marginBottom: 110, marginTop: 32 }}>{bodyContent}</div>

      <div className={classes.buttons}>
        {
          state.activeStep !== 0 &&
          <Button
            color='primary'
            style={{ width: 120, height: 48 }}
            disabled={submittingJob}
            onClick={onBackClick}
            data-test-id="back-button"
          >
            BACK
          </Button>
        }
        <Button data-test-id="cancel-button" color='primary' style={{ marginLeft: 30, width: 120, height: 48 }} onClick={onCancelClick} >
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          data-test-id="next-button"
          style={{ marginLeft: 30, width: 120, height: 48, marginRight: '10%' }}
          disabled={!stepClickable(state.activeStep + 1) || submittingJob}
          onClick={onNextClick}
        >
          {state.activeStep === steps.length - 1 ? 'CONFIRM' : 'NEXT'}
        </Button>
      </div>
    </div>
  )
}

Body.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(Body));