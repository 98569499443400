import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme, withStyles, Typography } from '@material-ui/core';
import SearchSelector from '../../../UI/SearchSelector/SearchSelector';
import PropertyItem from '../UpdateProperties/PropertyItem';

const styles = theme => ({
  header: {
    fontSize: 20,
    color: theme.palette.primaryText.main,
    marginBottom: 16,
  },
})

function LinkToInstance(props) {

  const {
    classes,
    state,
    dispatch,
    // history,
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const properties = state.properties;
  const setProperties = (properties) => dispatch({ type: 'set_properties', properties });

  const getInitialvalues = (property) => {
    let field = 'MEMBER_OF';
    let values = [];
    let valueDetails = [];
    let operation = 'ADD_TO_EXISTING';

    return {
      field,
      operation,
      values,
      valueDetails
    }
  }

  const onAddProperty = newProps => {
    let newProperties = [...properties, ...newProps];
    let newPropertyValues = { ...state.propertyValues };
    newProperties.forEach(p => {
      if (!newPropertyValues[p.id]) {
        newPropertyValues[p.id] = getInitialvalues(p)
      }
    })
    dispatch({
      type: 'set_property_values',
      propertyValues: newPropertyValues
    })
    setProperties(newProperties)
  }

  const onRemoveProperty = id => {
    let newProperties = properties.filter(p => p.id !== id)
    let newPropertyValues = { ...state.propertyValues };
    delete newPropertyValues[id];
    dispatch({
      type: 'set_property_values',
      propertyValues: newPropertyValues
    })
    setProperties(newProperties)
  }

  return (
    <div>
      <Typography className={classes.header}>
        Link to Collections
      </Typography>
      <div style={{ maxWidth: 800, marginBottom: 24 }}>
        <SearchSelector
          url='/solr/search/select'
          testID={'add-property-search'}
          params={{
            q: `${searchValue}*`,
            fq: `object_type_srt:COLLECTION AND collection_type_srt:(DATA_MANAGEMENT OR DATA_GOVERNANCE)` +
              `${properties.length > 0 ? ` AND -id:(${properties.map(p => p.id).join(' ')})` : ''}`,
            fl: '*',
            sort: 'name_srt asc',
            rows: 10
          }}
          autoSuggestion
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          placeholder={'Search for a property'}
          onResultClick={el => {
            onAddProperty([el])
          }}
        />
      </div>
      <>
        {
          properties.length === 0 &&
          <Typography>
            No collections selected
          </Typography>
        }
        {
          properties.map(p => {
            return (
              <PropertyItem
                property={p}
                key={p.id}
                state={state}
                dispatch={dispatch}
                onRemoveProperty={onRemoveProperty}
              />
            )
          })
        }
      </>
    </div>
  )
}

LinkToInstance.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LinkToInstance));