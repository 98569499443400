import React, { useEffect, useState} from 'react';
import { withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent, Select, FormControl, InputAdornment, MenuItem, FormHelperText, InputBase, CircularProgress} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import SimpleResultItem from '../../UI/SearchResults/SimpleResultItem';
import { getIconComponent, getIconLabelByIntegration } from '../../../utilities'
import KTooltip from '../../UI/KTooltip/KTooltip';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  selector: {
    ...theme.components.selector,
    '& div div':{
      paddingLeft:16,
      fontSize:16
    },
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputBase:{
    ...theme.components.inputBase,
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputField:{
    ...theme.components.inputBase,
    width:618,
    marginTop:8,
    '& textarea':{
      padding:'8px 16px'
    }
  },
  detailInputBase:{
    ...theme.components.inputBase,
    width:618,
    '& input':{
      padding:'16px 16px'
    }
  },
  checkbox:{
    color:theme.palette.primary.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  inputContainer:{
    marginBottom:16
  },
  tooltip:{
    fontSize:13.75,
  },
  inputTitle:{
    color:theme.palette.primary.main,
    fontSize:13.75,
    marginRight:8
  },
  instruction:{
    width: '30vw',
    display:'flex',
    flexDirection:'column'
  },

  focusBorder:{
    border: `1px solid ${theme.palette.error.main}`
  }
})

const CreateIntegration = props => {

  const {
    classes,
    state,
    dispatch,
    editedIntegration
    // sessionData,
    // history
  } = props;

  const [integration, setIntegration] = useState('none')
  const [name, setName] = useState('')
  const [details, setDetails] = useState({})
  const [creating, setCreating] = useState(false)
  const [createdIntegration, setCreatedIntegration] = useState()

  const [drawerVisible, setDrawerVisible] = useState(true)
  const [drawerHeight, setDrawerHeight] = useState(0)
  const [drawerTopMargin, setDrawerTopMargin] = useState(0)

  const {
    sendAlert
  } = useAlert({
    id:`create-integration`,
  })

  window.onscroll = () => {
    setDrawerTopMargin(window.scrollY)
  }

  useEffect(()=>{
    let header = document.getElementById('profile-header')
    if(header){
      let topOffset = header.getBoundingClientRect().height + 120;
      setDrawerHeight(window.innerHeight - topOffset)
    }
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    if([30,21].includes(integration) && !editedIntegration){
      setDetails({jira_field_name:'customfield_'})
    }
   // eslint-disable-next-line
  },[integration] )

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[state.createIntegrationStep])

  const loadAvailableIntegration = ({page=1,previousData=[]}) => {
    if(page===1){
      dispatch({type:'set_integration_templates',integrationTemplatesLoading:true})
    }
    axiosCerebrum
      .get(
        `/api/sourcetemplates`,
        {params:{
          page,
          per_page:10,
          types:'EXTERNAL_INTEGRATION',
          capabilities:state.viewedCapability
        }}
      )
      .then(response=>{
        if(response.data.page<response.data.pages){
          loadAvailableIntegration({page:response.data.page+1,previousData:[...previousData,...response.data.items]})
        }else{
          dispatch({type:'set_integration_templates',integrationTemplates:[...previousData,...response.data.items].filter(el=>el.type==='EXTERNAL_INTEGRATION')})
        }
      })
      .catch(error=>{
        dispatch({type:'set_integration_templates',integrationTemplatesError:true})
      })
  }

  useEffect(()=>{
    loadAvailableIntegration({});
    if(editedIntegration){
      setIntegration(editedIntegration.source_template.id)
      setName(editedIntegration.name)
      let args = editedIntegration.args;
      if(!args['jira_field_name'].match(/^customfield_(\d+)$/)){
        args['jira_field_name'] = 'customfield_'
      }
      setDetails(args)
      dispatch({type:'set_create_integration_step',createIntegrationStep:1})
    }
  // eslint-disable-next-line
  },[])

  const steps = [
    'Select an integration type',
    'Add a name',
    'Add details',
    (editedIntegration?'Save':'Create') + ' integration'
  ]


  const detailsFilled = () => {
    if(!state.integrationTemplates || integration==='none')return false;
    let template = state.integrationTemplates.find(el=>el.id===integration);
    if(template.args.length===0)return true;
    return template.args.every(el=>(
      el.optional || 
      ( details[el.populates] && details[el.populates].trim()!=='' && 
        (el.populates!=='jira_field_name' || details[el.populates].match(/^customfield_(\d+)$/)) && 
        (el.type!=='DROPDOWN_SINGLE' || details[el.populates]!=='none')
      )
    ))
  }


  const labelClickable = index => {
    switch(index){
      case 0:
        return !editedIntegration;
      case 1:
        return integration!=='none'
      case 2:
        return labelClickable(1) && name.trim()!==''
      case 3:
        return labelClickable(2) && detailsFilled()
      default:
    }
  }

  const getStepLabelText = index => {
    switch(index){
      case 0:
        if(state.createIntegrationStep>0 && state.integrationTemplates)return `${steps[0]}: ${state.integrationTemplates.find(el=>el.id===integration).name}`
        return steps[0];
      case 1:
        if(state.createIntegrationStep>1)return `${steps[1]}: ${name}`
        return steps[1];
      case 2:
        if(state.createIntegrationStep>2){
          let siteNameArg = state.integrationTemplates.find(el=>el.id===integration).args.find(el=>el.name==='Site Name')
          if(siteNameArg){
            return `${steps[2]}: ${details[siteNameArg.populates]}`
          }
        }
        return steps[2]
      case 3:
        if(createdIntegration)return `${steps[3]}: Completed`
        return steps[3]
      default:
    }
  }

  const onReset = () => {
    if(editedIntegration){
      setIntegration(editedIntegration.source_template.id)
      setName(editedIntegration.name)
      setDetails(editedIntegration.args)
      dispatch({type:'set_create_integration_step',createIntegrationStep:1})
    }else{
      setIntegration('none')
      setName('')
      setDetails({})
      dispatch({type:'set_create_integration_step',createIntegrationStep:0})
    }
  } 

  // to do 
  const onLabelClick = index => {
    if(labelClickable(index))dispatch({type:'set_create_integration_step',createIntegrationStep:index})
  }


  const onIntegrationChange = value => {
    setIntegration(value)
    if(value!=='none'){
      dispatch({type:'set_create_integration_step',createIntegrationStep:1})
      let newDetails = {}
      let selectedTemplate = state.integrationTemplates.find(el=>el.id===value);
      selectedTemplate.args.forEach(el=>{
        newDetails[el.populates] = el.default;
        if(el.name==='Capability' && el.allowed_values.includes(state.viewedCapability)){
          newDetails[el.populates] = state.viewedCapability;
        }
      })
      setDetails(newDetails)
    }
  }

  const onNameChange = value => {
    setName(value)
  }

  const onConfirmName = () => {
    if(editedIntegration && editedIntegration.name===name){
      dispatch({type:'set_create_integration_step',createIntegrationStep:2})
      return;
    }
    axiosCerebrum
      .get(`/api/sources`,{params:{name:name,per_page:0}})
      .then(response=>{
        if(response.data.total!==0){
          sendAlert({type:'error',message:"The integration name already exists"})
        }else{
          dispatch({type:'set_create_integration_step',createIntegrationStep:2})
        }
      })
    
  }

  const onCreate = () => {
    let payload = {}
    let selectedTemplate = state.integrationTemplates.find(el=>el.id===integration);
    payload.source_template_id = selectedTemplate.id;
    payload.args = details;
    payload.name = name;
    payload.full_load = true;
    payload.job_mode = 'LOAD_FROM_FILE';
    payload.enable_access_docs = false;
    payload.access_request_instructions = '';
    payload.access_request_url = '';
    payload.access_request_show_roles = false;
    if(!editedIntegration)payload.host = name;
    if(editedIntegration){
      payload.cron = {
        hour:editedIntegration.cron.hour,
        minute:editedIntegration.cron.minute,
        day_of_month:editedIntegration.cron.day_of_month,
        month_of_year:editedIntegration.cron.month_of_year,
        day_of_week:editedIntegration.cron.day_of_week
      }
      payload.enable_access_docs = editedIntegration.enable_access_docs;
      payload.access_request_instructions = editedIntegration.access_request_instructions;
      payload.access_request_url = editedIntegration.access_request_url;
      payload.access_request_show_roles = editedIntegration.access_request_show_roles;
      payload.active_flag = editedIntegration.active_flag;
    }
    let request;
    if(editedIntegration)request = axiosCerebrum.put('/api/sources/'+editedIntegration.id,payload)
    else{request = axiosCerebrum.post('/api/sources',payload)}
    
    setCreating(true)
    request.then(async response=>{
      setCreatedIntegration(response.data)
      setCreating(false)
      dispatch({type:'set_integration_list',integrationList:{}})
      sendAlert({type:'info',message:`Integration successfully ${editedIntegration?'saved':'created'}`})
    })
    .catch(error=>{
      let msg = `Error occurred ${editedIntegration?'saving':'creating'} the integration, please try again`
      if(error.response && error.response.status && error.response.status===409){
        msg = 'The integration name already exists'
      }
      console.log(error)
      setCreating(false)
      sendAlert({type:'error',message:msg})
    })
  }


  const IntegrtaionTypeSelector = (
    <div className={classes.block}>
      <FormControl>
        <Select
          className={classes.selector}
          value={integration}
          onChange={event=>onIntegrationChange(event.target.value)}
          startAdornment={
            <InputAdornment style={{marginLeft:16}}>{getIconComponent({label:'integration',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
          }
          renderValue={el=>{
            return (
              (el==='none' || !state.integrationTemplates || state.integrationTemplates.length===0)
                ?
                <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                  Select an Integration
                </Typography>
                :
                <div style={{marginLeft:-14}}>
                  <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                    Select an Integration
                  </Typography>
                  <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                    {state.integrationTemplates.find(s=>s.id===el).name}
                  </Typography>
                </div>
                
            )
          }}
        > 
          <MenuItem className={classes.menuItem} value={'none'}>
            Select an Integration
          </MenuItem>
          {
            state.integrationTemplates && state.integrationTemplates.map(el=>(
              <MenuItem className={classes.menuItem} value={el.id}>
                {el.name}
              </MenuItem>
            ))
          }
        </Select>
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
    </div>
  )

  const nameInput = (
    <div className={classes.block}>
      <FormControl>
        <InputBase
          value={name}
          className={classes.inputBase + (name.trim()===''?' '+classes.focusBorder:'')}
          onChange={event=>onNameChange(event.target.value)}
          startAdornment={
            <InputAdornment style={{marginLeft:16,marginRight:16}}>{getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
          }
          placeholder="The name of this integration in K"
        />
        <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>Required</FormHelperText>
      </FormControl>
      <Button 
        style={{marginTop:16}} 
        onClick={()=>onConfirmName()} 
        color="primary" 
        variant="contained" 
        disabled={!name || name.trim()===''}
      >
        NEXT
      </Button>
    </div>
  )

  const onInputChange = (type, value, populates) => {
    if(type==='INTEGER'){
      if(!isNaN(value)||value===''){
        setDetails({...details,[populates]:value})
      }
    }
    if(type==='STRING'){
      if(populates==='jira_field_name'){
        if(!value.match(/^customfield_(\d+|)$/)){
          if(details[populates] && details[populates].match(/^customfield_/))return;
          setDetails({...details,[populates]:'customfield_'})
          return;
        }
      }
      setDetails({...details,[populates]:value})
    }
    if(type==='DROPDOWN_SINGLE'){
      setDetails({...details,[populates]:value})
    }
  }

  const onTestLink = () => {
    axiosCerebrum
      .get(`/api/sources/${createdIntegration.id}/deeplink`)
      .then(response=>{ 
        window.open(response.data.link,'_blank')
      })
      .catch(error=>{
        console.log(error)
        sendAlert({type:'error',message:'Error occurred getting the link, please try again'})
      })
  }

  const generateDetailsInput = () => {
    if(!state.integrationTemplates || integration==='none')return;
    let template = state.integrationTemplates.find(el=>el.id===integration)
    if(!template || template.args.length===0)return <Typography className={classes.normalText}>No details required.</Typography>
    let inputs = [];
    template.args.forEach(el=>{
      inputs.push(
        <div className={classes.inputContainer}>
          <div style={{display:'flex',alignItems:'center',marginBottom:8}}>
            <Typography className={classes.inputTitle}>
              {el.name}
            </Typography>
            <KTooltip title={el.description} classes={{tooltip:classes.tooltip}} placement="right">
              {getIconComponent({label:'info',size:20,colour:theme.palette.primary.main})}
            </KTooltip>
          </div>
          {
            (el.type==='STRING' || el.type==='INTEGER') && 
            <FormControl>
              <InputBase
                value={details[el.populates]||''}
                className={
                  classes.detailInputBase + 
                  (
                    ((!details[el.populates] ||details[el.populates].trim()==='') || (el.populates==='jira_field_name' && !details[el.populates].match(/^customfield_(\d+)$/)))  && 
                    el.required
                    ?' '+classes.focusBorder:''
                  )}
                hidden={true}
                type={el.secret?'password':undefined}
                onChange={event=>{onInputChange(el.type,event.target.value,el.populates)}}
                placeholder={el.description}
              />
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>{el.required?'Required':'Optional'}</FormHelperText>
            </FormControl>
          }
          {
            (el.type==='DROPDOWN_SINGLE') && 
            <FormControl>
              <Select
                disableUnderline
                className={classes.selector + (el.required && (!details[el.populates]||details[el.populates]==='none')?' '+classes.focusBorder:'')}
                value={details[el.populates]||'none'}
                onChange={event=>onInputChange(el.type,event.target.value,el.populates)}
                startAdornment={
                  <InputAdornment style={{marginLeft:16}}>{getIconComponent({label:'info',size:24,colour:theme.palette.primaryText.light})}</InputAdornment>
                }
                renderValue={v=>{
                  return (
                    v==='none'
                      ?
                      <Typography style={{color:theme.palette.primaryText.light,fontSize:16}}>
                        {el.description}
                      </Typography>
                      :
                      <div style={{marginLeft:-14}}>
                        <Typography style={{color:theme.palette.primaryText.light,fontSize:12}}>
                          {el.description}
                        </Typography>
                        <Typography style={{fontSize:16,color:theme.palette.primaryText.main}}>
                          {v}
                        </Typography>
                      </div>
                      
                  )
                }}
              > 
                <MenuItem className={classes.menuItem} value={'none'}>
                  {el.description}
                </MenuItem>
                {
                  el.allowed_values.map(el=>(
                    <MenuItem className={classes.menuItem} value={el}>
                      {el}
                    </MenuItem>
                  ))
                }
              </Select>
              <FormHelperText style={{marginLeft:18, color:theme.palette.primaryText.light}}>{el.required?'Required':'Optional'}</FormHelperText>
            </FormControl>
          }
        </div>
      )
    })
    return inputs;
  }

  const detailsInput = (
    <div className={classes.block}>
      {generateDetailsInput()}
      <Button style={{marginTop:16}} onClick={()=>dispatch({type:'set_create_integration_step',createIntegrationStep:3})} disabled={!detailsFilled()} color="primary" variant="contained" >NEXT</Button>
    </div>
  )

  const createButton = (
    <div className={classes.block}>
      {
        creating && <div style={{marginTop:16}}><CircularProgress style={{width:16,height:16}} color='secondary'/></div>
      }
      {
        !creating && !createdIntegration && <Button style={{marginTop:16}} onClick={onCreate} color="primary" variant="contained" >{editedIntegration?'SAVE':'CREATE'}</Button>
      }
      {
        createdIntegration && 
        <div className={classes.block}>
          <Typography style={{fontSize:13.75,color:theme.palette.primaryText.light,marginBottom:8,whiteSpace:'pre-wrap'}}>{`Great, you have ${editedIntegration?'updated':'added'} an integration for ${state.viewedCapability.toLowerCase()}. The integration will now appear in K.\nClick to test the link.`}</Typography>
          <SimpleResultItem
            item={createdIntegration}
            label={getIconLabelByIntegration(createdIntegration)}
            titleColour={theme.palette.primaryText.main}
            title={createdIntegration.name}
            hideRight={true}
            showUnderline
            onClick={onTestLink}
          />
        </div>
      }
    </div>
  )

  // to do 
  const getStepContent = index => {
    switch(index){
      case 0:
        return IntegrtaionTypeSelector;
      case 1:
        return nameInput
      case 2:
        return detailsInput
      case 3:
        return createButton
      default:
    }
  }

  let iframeUrl;

  if(state.integrationTemplates && state.integrationTemplates.find(s=>s.id===integration) && state.integrationTemplates.find(s=>s.id===integration).name==='JIRA'){
    iframeUrl = 'https://kada-knowledge-library.scrollhelp.site/home/JIRA.1888683419.html'
  }

  return (
    <div className={classes.root}>
      <div style={{display:'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
        <div>
          <Typography className={classes.title}>INTEGRATION DETAILS</Typography>
          <Stepper activeStep={state.createIntegrationStep} orientation="vertical" className={classes.stepper} >
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel  className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
                  <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>
                    {getStepLabelText(index)}
                  </Typography> 
                </StepLabel>
                <StepContent>
                {getStepContent(index)}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
        {
          iframeUrl &&
          <div className={classes.instruction} style={{marginTop:drawerTopMargin, height:drawerHeight}}>
            <Button color='primary' style={{flex:'0 0',marginBottom:16, alignSelf:'flex-end'}} onClick={()=>setDrawerVisible(!drawerVisible)}>{drawerVisible?'HIDE GUIDE':'SHOW GUIDE'}</Button>
            <iframe  style={{ width:'100%', flex:'1 1', border:`1px solid ${theme.palette.listItemDivider.main}`, borderRadius:4, visibility:drawerVisible?"visible":"hidden"}} src={iframeUrl} title="instructions">
            </iframe>
          </div>
        }
      </div>

      <div className={classes.buttons}>
        {
          !createdIntegration && 
          <Button color='primary' onClick={onReset} style={{height:48,width:120}} >
            RESET
          </Button>
        }
        <Button variant='contained' color='primary' style={{marginLeft:30,height:48,marginRight:180,width:120}} onClick={()=>dispatch({type:'set_tab_state',tabState:1})}>
          {createdIntegration?"CLOSE":"CANCEL"}
        </Button>
      </div>

    </div>
  )
}


export default withStyles(styles)(CreateIntegration);