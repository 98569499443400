import React, { useEffect, useState } from 'react';
import { withStyles, Typography, Button, Stepper, Step, StepLabel, StepContent, Select, MenuItem, InputBase} from '@material-ui/core';
import theme from '../../../theme';
import axiosCerebrum from '../../../axios-cerebrum'
import { getPlatformSettings, updatePlatformSettings, } from '../../../permissionChecker';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  title:{
    color:theme.palette.header.main,
    fontSize:20,
    marginBottom:8
  },
  block:{
    maxWidth:640,
    marginLeft:6,
    marginTop:8
  },
  buttons:{
    position:'fixed',
    backgroundColor:theme.palette.background.main,
    height:80,
    bottom:0,
    right:0,
    left:40,
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-end',
    boxShadow:'0px -1px 5px 0px #DDD'
  },
  stepper:{
    marginBottom:120,
    backgroundColor:theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg':{
      color:theme.palette.primaryText.light
    },
  },
  selector: {
    ...theme.components.selector,
    '& div div':{
      paddingLeft:16,
      fontSize:16
    },
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputBase:{
    ...theme.components.inputBase,
    width: 618,
    height:56,
    marginTop: 8,
  },
  inputField:{
    ...theme.components.inputBase,
    width:618,
    marginTop:8,
    '& textarea':{
      padding:'8px 16px'
    }
  },
  detailInputBase:{
    ...theme.components.inputBase,
    width:618,
    '& input':{
      padding:'16px 16px'
    }
  },
  checkbox:{
    color:theme.palette.primary.main
  },
  normalText:{
    color:theme.palette.primaryText.main
  },
  inputContainer:{
    marginBottom:16
  },
  tooltip:{
    fontSize:13.75,
  },
  inputTitle:{
    color:theme.palette.primary.main,
    fontSize:13.75,
    marginRight:8
  },
  focusBorder:{
    border: `1px solid ${theme.palette.error.main}`
  }
})


const CreateGenAI = props => {

  const {
    classes,
    state,
    dispatch,
    hasGenAI,
    // sessionData,
    // history
  } = props;

  const [type, setType] = useState('azure_gpt4');
  const [url, setUrl] = useState(hasGenAI?(getPlatformSettings(10001)?.value||''):'')
  const [key, setKey] = useState('')
  const [creating, setCreating] = useState(false)
  const [integrationCreated, setIntegrationCreated] = useState(false)

  const {
    sendAlert
  } = useAlert({
    id:`create-gen-ai-integration`,
  })

  useEffect(()=>{
    dispatch({type:'set_create_integration_step',createIntegrationStep:1})
  // eslint-disable-next-line
  },[])

  useEffect(()=>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  },[state.createIntegrationStep])

  const steps = [
    'Select an Integration Type'+(type!=='none'?`: ${type}`:''),
    url.trim()!=='' && state.createIntegrationStep>1?'API Url added':'Add API Url',
    key.trim()!=='' && state.createIntegrationStep>2?'API Key added':'Add API Key',
    (hasGenAI?'Save':'Create') + ' integration'
  ]

  const typeSelected = () => {
    return type!=='none'
  }

  const urlFilled = () => {
    return url.trim()!==''
  }

  const keyFilled = () => {
    return key.trim()!==''
  }

  const labelClickable = index => {
    if(index===1)return typeSelected()
    if(index===2)return typeSelected() && urlFilled()
    if(index===3)return typeSelected() && urlFilled() && keyFilled()
    return true
  }

  const getStepLabelText = index => {
    switch(index){
      case 0:
        return steps[0];
      case 1:
        return steps[1];
      case 2:
        return steps[2];
      case 3:
        return steps[3];
      default:
    }
  }

  const onReset = () => {
    dispatch({type:'set_create_integration_step',createIntegrationStep:0})
    setUrl('')
    setKey('')
  } 

  const onLabelClick = index => {
    if(labelClickable(index))dispatch({type:'set_create_integration_step',createIntegrationStep:index})
  }

  const onSave = () => {
    setCreating(true)
    axiosCerebrum[hasGenAI?'put':'post'](
        `/api/integrations/llm`,{
          url:url,
          api_key:key
        }
      )
      .then(response=>{
        setCreating(false)
        setIntegrationCreated(true)
        sendAlert({type:'info',message:`Integration successfully ${hasGenAI?'updated':'created'}`})
        dispatch({type:'set_tab_state',tabState:1})
        dispatch({type:'set_has_gen_ai',hasGenAI:true})
        updatePlatformSettings({...(getPlatformSettings(10003)||{}),value:'true'})
        updatePlatformSettings({...(getPlatformSettings(10000)||{}),value:key})
        updatePlatformSettings({...(getPlatformSettings(10001)||{}),value:url})
      })
      .catch(error=>{
        setCreating(false)
        dispatch({type:'set_has_gen_ai',hasGenAI:false})
        sendAlert({type:'error',message:`Error occurred ${hasGenAI?'updating':'creating'} the integration`})
      })
  }

  const typeSelector = (
    <div>
      <Select
        value={type}
        onChange={e=>{
          setType(e.target.value)
          if(e.target.value!=='none'){
            dispatch({type:'set_create_integration_step',createIntegrationStep:1})
          }
        }}
        className={classes.selector}
      >
        <MenuItem value={'none'}>
          Select Integration Type
        </MenuItem>
        <MenuItem value={'azure_gpt4'}>
          Azure GPT4
        </MenuItem>
      </Select>
    </div>
  )

  const urlInput = (
    <div>
      <InputBase
        className={classes.inputBase}
        value={url}
        onChange={event=>setUrl(event.target.value)}
        placeholder='Enter API Url'
      />
      <Button 
        color='primary' 
        variant='contained' 
        disabled={!url.trim()} 
        onClick={()=>{dispatch({type:'set_create_integration_step',createIntegrationStep:2})}}
        style={{marginTop:16,display:'block'}}
      >
        NEXT
      </Button>
    </div>
  )

  const keyInput = (
    <div>
      <InputBase
        className={classes.inputBase}
        value={key}
        type='PASSWORD'
        placeholder='Enter API Key'
        onChange={event=>setKey(event.target.value)}
      />
      <Button 
        color='primary' 
        variant='contained' 
        disabled={!key.trim()} 
        onClick={()=>{dispatch({type:'set_create_integration_step',createIntegrationStep:3})}}
        style={{marginTop:16,display:'block'}}
      >
        NEXT
      </Button>
    </div>
  )

  const saveButton = (
    <div>
      <Button style={{marginTop:16}} color='primary' variant='contained' disabled={creating} onClick={onSave}>
        SAVE 
      </Button>
    </div>
  )

  const getStepContent = index => {
    switch(index){
      case 0:
        return typeSelector;
      case 1:
        return urlInput;
      case 2:
        return keyInput;
      case 3:
        return saveButton;
      default:
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>INTEGRATION DETAILS</Typography>

      <Stepper activeStep={state.createIntegrationStep} orientation="vertical" className={classes.stepper} >
        {steps.map((label, index) => (
          <Step key={index}>
            <StepLabel  className={classes.step} style={{cursor:labelClickable(index)?'pointer':undefined}} onClick={()=>{onLabelClick(index)}}>
              <Typography color='primary' style={{color:state.settingStep<index?theme.palette.primaryText.light:theme.palette.primaryText.main,fontSize:16,marginLeft:6}}>
                {getStepLabelText(index)}
              </Typography> 
            </StepLabel>
            <StepContent>
             {getStepContent(index)}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      
      <div className={classes.buttons}>
        <Button color='primary' onClick={onReset} style={{height:48,width:120}} >
          RESET
        </Button>
        <Button variant='contained' color='primary' style={{marginLeft:30,height:48,marginRight:180,width:120}} onClick={()=>dispatch({type:'set_tab_state',tabState:1})}>
          {integrationCreated?"CLOSE":"CANCEL"}
        </Button>
      </div>
    </div>
  )
}


export default withStyles(styles)(CreateGenAI);