import React, { } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
// import * as actions from '../../../store/actions/actionTypes';
import { checkCanBulkUpdate, checkCanUploadBulkFile, nonIAObjects, templateObjects } from '../../../permissionChecker';
import { CerebrumLongListLoader } from '../../../LongListLoader';
import DropdownButton from '../../UI/Buttons/DropdownButton';
import useAlert from '../../../hooks/useAlert';

const styles = theme => ({
  infoBox:theme.components.infoBox,
  infoHeader:theme.components.infoBoxHeader,
  infoDescription:theme.components.infoBoxDescription,
  focusTxt:theme.components.infoBoxFocusTxt,
  button:theme.components.infoBoxButtonOutlined,
  disabledButton:{
    border:`1px solid ${theme.palette.primaryText.light}`
  },
  sectionHeader:{
    fontSize:13.75,
    letterSpacing:1
  }
})

function InfoBox(props) {

  const {
    classes,
    // dispatch,
    state,
    history,
    sessionData
  } = props;
  
  const [loadingCartItems, setLoadingCartItems] = React.useState(false)

  const totalCount = state.tabs.reduce((acc,el)=>acc+el.count,0)
  const disableTemplateBulk = state.tabs.every(t=>!templateObjects.includes(t.objectType)) || !checkCanUploadBulkFile({sessionData})
  // const reduxDispatch = useDispatch()

  const {
    sendAlert
  } = useAlert({
    id:`cart-info-box`
  })

  const isDisableIA = totalCount>25 || state.tabs.every(t=>nonIAObjects.includes(t.objectType))

  const onCreatTemplate = () => {
    // history.push('/bulk_edit_templated?tabName=MY TEMPLATES')
    history.push('/bulk_edit_templated?view=create_template&cancel_redirect_url=/cart')
  }

  const onImportTemplate = () =>  {
    history.push('/bulk_edit_templated?view=history&open_import_modal=true')
  }

  // const onBulkEdit = () => {
  //   reduxDispatch({
  //     type:actions.SET_BULK_EDIT_PARAM,
  //     data:{
  //       redirectUrl:window.location.pathname.split('?')[0],
  //       editMode:'cart_bulk_edit',
  //     }
  //   })
	// 	history.push('/bulk_edit')
  // }

  const onRunIA = (type) => {
    sendAlert({type:'info',message:'Loading cart items to app'})
    setLoadingCartItems(true)
    CerebrumLongListLoader({
      url:'/api/me/cart',
      params:{},
      per_page:500,
      onFinishLoad:({data})=>{
        history.push(`/${type==='ia'?'impact_assessment':"dependency_assessment"}?multiTargetId=${data.filter(el=>!nonIAObjects.includes(el.object.name)).map(el=>el.id).join(',')}`)
        setLoadingCartItems(false)
      },
      onError:()=>{
        setLoadingCartItems(false)
        sendAlert({type:'error',message:'Error occurred starting app, please try again'})
      }
    })
  }

  return (
    <div className={classes.infoBox}>
      <Typography className={classes.infoHeader}>About Data Cart</Typography>
      <div style={{display:'flex',alignItems:'flex-start'}}>
        <Typography className={classes.infoDescription}>
          The Data Cart allows you to collect multiple items to perform bulk actions such as:
          {'\n\n'}
          <span style={{fontWeight:700}}>Bulk edit:</span>
          {'\n'}
          <ul style={{paddingInlineStart:16}}>
            <li>
              <span style={{fontWeight:700}}>Bulk Edit via Excel</span> that includes items in your data cart. Performing bulk edits via <span className={classes.focusTxt} onClick={onImportTemplate}>importing the bulk edit file</span> is recommended for scenarios where you have more than 500 data items in your Data Cart. This option creates an excel file that can be socialise, reviewed and approved with stakeholders outside of the K Platform.
            </li>
          </ul>
          {'\n'}
          <span style={{fontWeight:700}}>Run data applications like:</span>
          {'\n'}
          <ul style={{paddingInlineStart:16}}>
            <li>
            <span style={{fontWeight:700}}>Impact Assessment</span> or <span style={{fontWeight:700}}>Dependency Assessment</span> on up to 25 items in a single assessment. Once you run the assessment, you have the option of deleting the data items that were included in the assessment so that you can then run a subsequent assessment on the next batch of items in your data cart.
            </li>
          </ul>
        </Typography>
        <div style={{textAlign:'-webkit-right',flexShrink:0}}>
          <div style={{width:'max-content',marginBottom:20}}>
            <DropdownButton
              text='BULK EDIT'
              noMargin
              height={34}
              isInfoBoxButton
              disabled={totalCount===0 || loadingCartItems || (!checkCanBulkUpdate({sessionData}) && !checkCanUploadBulkFile({sessionData}))}
              tooltip={(!checkCanBulkUpdate({sessionData}) && !checkCanUploadBulkFile({sessionData}))?'You do not have permission to use this feature':null}
              // primaryAction={onCreatTemplate}
              optionList={[
                // checkCanBulkUpdate({sessionData})?
                //   {onClick:onBulkEdit,text:'BULK UPDATE', iconLabel:'bulk_edit_k'}
                //   :
                //   undefined
                // ,
                checkCanUploadBulkFile({sessionData})?
                  {onClick:onCreatTemplate,text:'BULK EDIT VIA EXCEL',tooltip:!checkCanUploadBulkFile({sessionData})?'You do not have permission to use this feature':null, disabled:disableTemplateBulk, iconLabel:'bulk_edit'}
                  :
                  undefined
                ,
              ]}
            />
          </div>
          <div style={{width:'max-content'}}>
            <DropdownButton
              text='DATA APPS'
              noMargin
              height={34}
              isInfoBoxButton
              disabled={totalCount===0 || loadingCartItems}
              optionList={[
                {onClick:()=>onRunIA('ia'),text:'IMPACT ASSESSMENT',disabled:isDisableIA, tooltip:isDisableIA?'Impact Assessment is only available for certain object types and limited to 25 items':null, iconLabel:'ia_downstream'},
                {onClick:()=>onRunIA('da'),text:'DEPENDENCY ASSESSMENT',disabled:isDisableIA, tooltip:isDisableIA?'Dependency Assessment is only available for certain object types and limited to 25 items':null, iconLabel:'ia_upstream'},
              ]}
            />
          </div>

        </div>
      </div>
    </div>
  )
}

InfoBox.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
}

export default withStyles(styles)(InfoBox);