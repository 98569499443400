import React, { useEffect, useRef, useState, useReducer } from 'react';
import { withTheme, withStyles, Typography, LinearProgress, IconButton} from '@material-ui/core';
import PropTypes from 'prop-types';
import {globalListenerRef} from '../../../../GlobalListenerRef';
// import {useStore} from 'react-redux'
import ReactFlowWrapper from '../../ReactFlowWrapper/ReactFlowWrapper';
// import { checkProfileEditable } from '../../../../permissionChecker';
import useAlert from '../../../../hooks/useAlert';
import { drawSwimlaneLabels, getFullLinkList, getFullNodeList, removeSwimlaneLabels } from './layoutUtils';
import { onClickExpandNode, onInitialiseRoot } from './dataLoadUtils';
// import { onDownloadMap } from '../utils';
import ObjectDetailDrawer from './Drawers/ObjectDetailDrawer';
import TagSelector from './Controls/LegendControls/TagSelector';
import ParentDrawer from './Drawers/ParentDrawer';
import LineageDetailDrawer from './Drawers/LineageDetailDrawer';
import SwimlaneDrawer from './Drawers/SwimlaneDrawer';
import MainControls from './Controls/MainControls/MainControls';
import NodeContextMenu from './Controls/NodeContextMenu/NodeContextMenu';
import { getIconComponent, sendMessage } from '../../../../utilities';
import { getDefaultLevel } from '../utils';

const styles = theme => ({
  chartWrapper:{
    width:'100%'
  },
  tooltip:{
    fontSize:13.75
  },
  emotRoot: {
    marginTop:80,
    display: 'flex',
    flexDirection: 'column',
    alignItems:'center',
    justifyContent:'center'
  },
  emot:{
    fontSize:40,
    color:theme.palette.primaryText.main
  },
  text:{
      fontSize:16,
      color:theme.palette.primaryText.light,
      marginTop:24
  },
  legendText:{
    fontSize:12,
    color:theme.palette.primary.main,
    marginLeft:16,
    letterSpacing:1.5
  },
  button:{
    width:20,
    height:20,
    borderRadius:16,
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    cursor:'pointer',
    '&:hover':{
      background:theme.palette.hovered.main
    }
  },
  iconButton:{
    padding:0
  }
})

function LineageV3(props) {

  const {
    classes,
    state,
    dispatch,
    history,
    theme,
    root,
    isLinkable,
    disableFocus,
    forceFocusOption,
    forceIsShowReference,
    upstreamOnly,
    downstreamOnly,
    hideControls,
    disableCache
  } = props;
  
  // const store = useStore();
  // const sessionData = store.getState().auth.session_user;

  // let isEditable = checkProfileEditable({sessionData, isStewardOrOwner: state.isStewardOrOwner})

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const isRootActive = typeof(root.active_flag)==='boolean'?root.active_flag:root.active_flag_txt==='YES'
 
  const [mapControls] = useState({
    isShowActiveOnly: typeof(state.lineageData.isShowActiveOnly)==='boolean'?state.lineageData.isShowActiveOnly:isRootActive,
    isShowReference: typeof(forceIsShowReference)==='boolean'?forceIsShowReference:(typeof(state.lineageData.isShowReference)==='boolean'?state.lineageData.isShowReference:true),
    isShowFullName: state.lineageData.isShowFullName || false,
    focusView: forceFocusOption?.focus || state.lineageData.focusView || [],
    subFocusView: forceFocusOption?.subFocus || state.lineageData.subFocusView || {},
    focusViewType: state.lineageData.focusViewType||'or',
    isViewExclude: state.lineageData.isViewExclude||false,
    tagView: state.lineageData.tagView || localStorage.getItem('lineage_tag_view') ||'trust',
    lineageLevel: state.lineageData.lineageLevel || getDefaultLevel(root.object?.name),
  })


  const mapReRenderTimeout = useRef() 

  const [objectDrawerOpen, setObjectDrawerOpen] = useState(false)
  const [parentDrawerOpen, setParentDrawerOpen] = useState(false)
  const [detailDrawerOpen, setDetailDrawerOpen] = useState(false)
  const [swimlaneDrawerOpen, setSwimlaneDrawerOpen] = useState(false)
  const [contextMenuOpen, setContextMenuOpen] = useState(false)
  const [selectedLevel, setSelectedLevel] = useState()
  const [selectedItem, setSelectedItem] = useState();

  const [groupNodes] = useState(state.lineageData.groupNodes || [])
  const [childrenMap] = useState(state.lineageData.childrenMap || {})
  const [links] = useState(state.lineageData.links || [])

  // eslint-disable-next-line
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  const [graphHeight, setGraphHeight] = useState(600)
  const [graphWidth, setGraphWidth] = useState(1000)

  const chartWrapperRef = useRef()
  const nodeWidth = 270;

  const isCancelledRef = useRef(false)

  const lineageEmptyMessageCache = useRef([])
  const lineageEmptyMessageTimeout = useRef()

  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(()=>{
    if(loading)setError(false)
  },[loading])

  useEffect(()=>{ 
    window.removeEventListener('message',globalListenerRef.lineageListener)
    globalListenerRef.lineageListener = (msg) => {
      if(!msg.data)return;
      if(msg.data.lineage_update_map){
        reRenderMap({})
      }
      if(msg.data.lineage_clicked_node_id){
        setSelectedItem(childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.lineage_clicked_node_id))
        setSelectedLevel()
        setObjectDrawerOpen(true)
        setParentDrawerOpen(false)
        setDetailDrawerOpen(false)
        setSwimlaneDrawerOpen(false)
      }
      if(msg.data.lineage_clicked_parent_id){
        let group = groupNodes.find(g=>g.id===msg.data.lineage_clicked_parent_id)
        setSelectedItem(group)
        if(group.isRoot){
          setObjectDrawerOpen(true)
          setParentDrawerOpen(false)
        }else{
          setObjectDrawerOpen(false)
          setParentDrawerOpen({direction:msg.data.direction})
        }
        setSelectedLevel()
        setDetailDrawerOpen(false)
        setSwimlaneDrawerOpen(false)
      }
      if(msg.data.lineage_detail_node_id){
        let item = childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.lineage_detail_node_id);
        setSelectedItem(item)
        if(!msg.data.is_code_only){
          let linkedItem = item?.parentNodeRef?.prevLevelRef;
          let upstreamData, downstreamData;
          item = item.parentNodeRef;
          if(item?.level<linkedItem?.level){
            upstreamData = item;
            downstreamData = linkedItem;
          }else{
            upstreamData = linkedItem;
            downstreamData = item;
          }
          let linkId = `${upstreamData.id}-${downstreamData.id}`
          let link = document.getElementById(linkId);
          if(link){
            link.dispatchEvent(new MouseEvent('click',{bubbles:true}))
          }
        }
        setObjectDrawerOpen(false)
        setParentDrawerOpen(false)
        setDetailDrawerOpen({is_code_only:msg.data.is_code_only})
        setSwimlaneDrawerOpen(false)
        setSelectedLevel()
      }
      if(msg.data.lineage_context_menu_id || msg.data.lineage_group_context_menu_id){
        setObjectDrawerOpen(false)
        setParentDrawerOpen(false)
        setDetailDrawerOpen(false)
        setSwimlaneDrawerOpen(false)
        setSelectedLevel()
        if(msg.data.lineage_group_context_menu_id){
          setSelectedItem(groupNodes.find(g=>g.id===msg.data.lineage_group_context_menu_id))
          setContextMenuOpen({node_id:msg.data.lineage_group_context_menu_id})
        }else{
          setSelectedItem(childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.lineage_context_menu_id))
          setContextMenuOpen({node_id:msg.data.lineage_context_menu_id})
        }
      }
      if(msg.data.clicked_swimlane_level){
        setSelectedLevel(msg.data.clicked_swimlane_level)
        setObjectDrawerOpen(false)
        setParentDrawerOpen(false)
        setDetailDrawerOpen(false)
        setSwimlaneDrawerOpen(true)
      }
      if(msg.data.lineage_node_link_changed && selectedItem){
        if(selectedItem.isRoot){
          initialiseTree()
          return;
        }
        onClickExpandNode({
          targetNode:selectedItem,
          currentGroups:groupNodes,
          currentLinks:links,
          currentChildrenMap:childrenMap,
          mapControls,
          direction:selectedItem.level<0?'UPSTREAM':"DOWNSTREAM",
          reRenderMap,
          forceReload:true
        })
      }
      if(msg.data.lineage_link_removed){
        let node = childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.node_id);
        if(node){
          if(node.level===0){
            initialiseTree()
          }else{
            onClickExpandNode({
              targetNode:node,
              currentGroups:groupNodes,
              currentLinks:links,
              currentChildrenMap:childrenMap,
              mapControls,
              direction:node.level<0?'UPSTREAM':"DOWNSTREAM",
              reRenderMap,
              forceReload:true
            })
          }
        }
      }
      if(msg.data.lineage_expand_node_id){
        setObjectDrawerOpen(false)
        setParentDrawerOpen(false)
        setDetailDrawerOpen(false)
        setSwimlaneDrawerOpen(false)
        setSelectedLevel()
        
        setSelectedItem(childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.lineage_expand_node_id))
        onClickExpandNode({
          targetNode:childrenMap[msg.data.parent_node_id].find(c=>c.id===msg.data.lineage_expand_node_id),
          currentGroups:groupNodes,
          currentLinks:links,
          currentChildrenMap:childrenMap,
          mapControls,
          direction:msg.data.direction,
          reRenderMap
        })
      }
      if(msg.data.lineage_select_node_id){
        if(!msg.data.parent_node_id){
          setSelectedItem(groupNodes.find(g=>g.id===msg.data.lineage_select_node_id))
        }else{
          setSelectedItem(childrenMap[msg.data.parent_node_id]?.find(c=>c.id===msg.data.lineage_select_node_id))
        }
      }
      if(msg.data.swimlane_show_label){
        drawSwimlaneLabels()
      }
      if(msg.data.swimlane_hide_label){
        removeSwimlaneLabels()
      }
      if(msg.data.lineage_alert_message){
        sendAlert({type:"info",message:msg.data.lineage_alert_message})
      }
      if(msg.data.lineage_empty_message){
        if(!lineageEmptyMessageCache.current.includes(msg.data.direction)){
          lineageEmptyMessageCache.current.push(msg.data.direction)
        }
        clearTimeout(lineageEmptyMessageTimeout.current)
        lineageEmptyMessageTimeout.current = setTimeout(()=>{
          if(['UPSTREAM','DOWNSTREAM'].every(d=>lineageEmptyMessageCache.current.includes(d))){
            sendAlert({type:"info",message:'No lineage found for this item'})
          }else{
            sendAlert({type:"info",message:`No ${lineageEmptyMessageCache.current[0]?.toLowerCase()} lineage found for this item`})
          }
          lineageEmptyMessageCache.current = []
        },1200)
      }
      if(msg.data.lineage_error){
        setError(true)
        setLoading(false)
      }
    }
    window.addEventListener('message',globalListenerRef.lineageListener)

    return () => {
      isCancelledRef.current = true
      window.removeEventListener('message',globalListenerRef.lineageListener)
      dispatch({
        type:"set_lineage_data",
        lineageData:{
          ...(state.lineageData||{}),
          groupNodes,
          childrenMap,
          links,
          ...mapControls
        }
      })
    }
  // eslint-disable-next-line
  },[groupNodes, links, childrenMap, selectedItem, mapControls, sendAlert])

  const setDimensions = () => {
    setGraphWidth(document.getElementById('profile-content').getBoundingClientRect().width)
    setGraphHeight(window.innerHeight - 118)
  }

  useEffect(()=>{
    setDimensions()
    window.removeEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
    globalListenerRef.lineageFullScreenResizeListener = () => {
      setDimensions()
    }
    window.addEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
    return ()=>window.removeEventListener('resize',globalListenerRef.lineageFullScreenResizeListener)
  },[])
  
  const reRenderMap = ({controls=mapControls}) => {
    // to prevent frequent re-rendering
    clearTimeout(mapReRenderTimeout.current)
    mapReRenderTimeout.current = setTimeout(()=>{
      forceUpdate();
    },100)
  }

  const onChangeMapControl = ({control,value}) => {
    mapControls[control] = value;
    reRenderMap({controls:mapControls});
    if(['isShowReference','isShowActiveOnly','lineageLevel'].includes(control)){
      setTimeout(()=>{
        initialiseTree()
      },150)
    }
  }
  
  const initialiseTree = async () => {
    // close all drawers
    setObjectDrawerOpen(false)
    setParentDrawerOpen(false)
    setDetailDrawerOpen(false)
    setSwimlaneDrawerOpen(false)
    setSelectedItem()

    groupNodes.splice(0,groupNodes.length)
    Object.keys(childrenMap).forEach(key=>{
      delete childrenMap[key]
    })
    links.splice(0,links.length)
    setLoading(true)
    onInitialiseRoot({
      rootObj:root, 
      currentGroups:groupNodes,  
      currentLinks:links,
      currentChildrenMap:childrenMap,
      upstreamOnly,
      downstreamOnly,
      mapControls,
      onFinish:()=>setLoading(false)
    })
    return;
  }

  useEffect(()=>{
    if(groupNodes.length===0 || disableCache){
      initialiseTree()
    }else{
      reRenderMap({})
    }
  // eslint-disable-next-line
  },[])

  const onSelectionChange = data => {
    if(data.edges.length===0 && data.nodes.length===0){
      setContextMenuOpen(false)
      setSelectedItem()
      setObjectDrawerOpen(false)
      setParentDrawerOpen(false)
      setDetailDrawerOpen(false)
      setSwimlaneDrawerOpen(false)
    }
  }
  

  let horizontalOffset = 0;
  if(downstreamOnly) horizontalOffset = graphWidth/2
  if(upstreamOnly)horizontalOffset = -graphWidth/2
  
  return (
    <div 
      className={classes.root}
      id={'k-lineage-v3-root'}
    >
      <div>
        {
          loading && 
          <div style={{textAlign:'center',marginTop:168}}>
            <LinearProgress color='secondary' style={{width:200,margin:'auto',marginBottom:24}}/>
            <Typography style={{fontSize:13.75,color:theme.palette.primaryText.main}}>Generating map</Typography>
          </div>
        }

        {
          error && !loading && 
          <div style={{display:'flex',flexDirection:'column',width:'100%',alignItems:'center',justifyContent:'center'}}>
            <div className={classes.emotRoot}>
              <Typography className={classes.emot}>¯\_(ツ)_/¯</Typography>
              <Typography className={classes.text}>Unfortunately I am not sure what went wrong.</Typography>
              <Typography className={classes.text}>Let your administrator know so they can fix the problem.</Typography>
            </div>
          </div>
        }
        {
          !loading && !error && 
          <div style={{display:'flex',alignItems:'center'}}>
            {
              graphWidth>=1250 &&
              <div
                style={{position:'relative',left:-64,width:0,overflow:'visible'}}
                onClick={()=>{
                  sendMessage({react_flow_move_viewport:'left'})
                }}
              >
                <IconButton className={classes.iconButton}>
                  {getIconComponent({label:'triangle_left',size:64})}
                </IconButton>
              </div>
            }
            <div 
              className={classes.chartWrapper} 
              style={{width:graphWidth,height:graphHeight}} 
              ref={chartWrapperRef}
              onClick={()=>{
                sendMessage({close_control_drawer:true})
              }}
            >
              {
                chartWrapperRef.current && 
                <ReactFlowWrapper
                  initialNodes={
                    getFullNodeList({
                      currentGroups:groupNodes, 
                      childrenMap:childrenMap, 
                      currentLinks:links, 
                      rootObject:root,
                      mapControls,
                      selectedItem,
                      selectedLevel
                    })
                  }
                  alwaysShowAllNodes
                  initialLinks={getFullLinkList({links, selectedItem})}
                  presetCenter={{x:horizontalOffset + nodeWidth/2,y:0}}
                  centerOffset={{x:nodeWidth/2,y:0}}
                  onFocusOffset={{x:200,y:0}}
                  hideMiniMap={true}
                  draggable={false}
                  onSelectionChange={onSelectionChange}
                />
              }
            </div>
            {
              graphWidth>=1250 &&
              <div
                style={{position:'relative',width:0,right:-8,overflow:'visible'}}
                onClick={()=>{
                  sendMessage({react_flow_move_viewport:'right'})
                }}
              >
                <IconButton className={classes.iconButton}>
                  {getIconComponent({label:'triangle_right',size:64})}
                </IconButton>
              </div>
            }
          </div>
        }
      </div>
      <TagSelector
        tagView={mapControls.tagView}
        onChangeTagView={tagView=>{
          onChangeMapControl({control:'tagView',value:tagView})
          localStorage.setItem('lineage_tag_view',tagView)
        }}
      />
      {
        !hideControls && 
        <MainControls
          disableFocus={disableFocus}
          mapControls={mapControls}
          onChangeMapControl={onChangeMapControl}
          initialiseTree={initialiseTree}
          dispatch={dispatch}
          history={history}
          childrenMap={childrenMap}
          currentGroups={groupNodes}
          root={root}
        />
      }
      <ObjectDetailDrawer
        history={history}
        isLinkable={isLinkable}
        drawerOpen={objectDrawerOpen}
        setDrawerOpen={setObjectDrawerOpen}
        selectedItem={selectedItem}
        tagView={mapControls.tagView}
        mapControls={mapControls}
      />
      <ParentDrawer
        history={history}
        selectedItem={selectedItem}
        drawerOpen={parentDrawerOpen}
        setDrawerOpen={setParentDrawerOpen}
        childrenMap={childrenMap}
        currentGroups={groupNodes}
        mapControls={mapControls}
      />
      <LineageDetailDrawer
        history={history}
        drawerOpen={detailDrawerOpen}
        setDrawerOpen={setDetailDrawerOpen}
        selectedItem={selectedItem}
        mapControls={mapControls}
      />
      <SwimlaneDrawer
        history={history}
        drawerOpen={swimlaneDrawerOpen}
        setDrawerOpen={setSwimlaneDrawerOpen}
        selectedLevel={selectedLevel}
        childrenMap={childrenMap}
        mapControls={mapControls}
        currentGroups={groupNodes}
        currentLinks={links}
      />
      <NodeContextMenu
        selectedItem={selectedItem}
        contextMenuOpen={contextMenuOpen}
        setContextMenuOpen={setContextMenuOpen}
        isLinkable={isLinkable}
      />
    </div>
  )
}

LineageV3.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

export default withTheme()(withStyles(styles)(LineageV3));