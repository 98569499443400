import React, { useEffect, useState } from 'react';
import { withTheme, Typography, withStyles, Button } from '@material-ui/core';
import axiosSolr from '../../../../axios-solr'
import { getIconComponent, onClickResultItem, getDispFields, collectionIds, sendMessage, getFontColourByBackground } from '../../../../utilities';
import SimpleResultItem from '../../../UI/SearchResults/SimpleResultItem'
import axiosCerebrum from '../../../../axios-cerebrum'
import { getCollectionObjectName } from '../../MainSearch/Utils/Utils';
import KTooltip from '../../../UI/KTooltip/KTooltip';

const styles = theme => ({
  root: {
    width: '100%',
  },
  errorText: {
    color: theme.palette.primaryText.main
  },
  header: {
    fontSize: 16,
    color: theme.palette.header.main,
    marginBottom: 16,
    marginTop: 40
  },
  sourceCountChip: {
    padding: '8px 12px',
    borderRadius: 16,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    fontSize: 12,
  },
  collectionChip: {
    padding: '0 16px 0 8px',
    height: 32,
    borderRadius: 16,
    display: 'flex',
    alignItems: 'center',
    maxWidth: 200,
    overflow: "hidden",
    border: `1px solid ${theme.palette.border.main}`,
    background: theme.palette.chip.main,
    margin: '0 8px 8px 0',
    cursor: "pointer",
    '&:hover': {
      // filter:'brightness(120%)',
      background: theme.palette.hovered.main
    }
  },
  collectionChipText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: 13.75,
    color: theme.palette.primaryText.main,
    marginLeft: 8
  }
});



const RecommendList = props => {

  const {
    history,
    theme,
    classes,
    state,
    dispatch,
    onSearch,
    variant = 'landing'
  } = props;

  const [teamKeySeeMore, setTeamKeySeeMore] = useState(false)

  let groupName, groupId;
  try {
    groupName = JSON.parse(localStorage.getItem('userGroupData'))[0].name;
    groupId = JSON.parse(localStorage.getItem('userGroupData'))[0].id;
  } catch {
  }

  const instancePerPage = 10;

  const getCollectionsData = async () => {
    let collectionRecommendation = {};
    let promises = [
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: "collection_srt:DOMAIN AND object_type_srt:COLLECTION_INSTANCE",
            rows: instancePerPage,
            sort: 'last_updated_srt desc'
          }
        }
        )
        .then(response => {
          if (response.data.response.docs.length > 0) {
            collectionRecommendation['Domains'] = response.data.response.docs
          }
        }),
      axiosCerebrum
        .get(
          `/api/collectioninstances`, {
          params: {
            page: 1,
            per_page: instancePerPage,
            sort: 'END_DESC',
            collection_id: collectionIds.category
          }
        }
        )
        .then(response => {
          if (response.data.items.length > 0) {
            collectionRecommendation['Categories'] = response.data.items
          }
        }),
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: 'collection_srt:"VERIFIED USE CASE" AND object_type_srt:COLLECTION_INSTANCE',
            rows: instancePerPage,
            sort: 'last_updated_srt desc'
          }
        }
        )
        .then(response => {
          if (response.data.response.docs.length > 0) {
            collectionRecommendation['Verified Use Cases'] = response.data.response.docs
          }
        }),
      axiosSolr
        .get(
          `/solr/search/select`, {
          params: {
            q: "*",
            fq: 'collection_type_srt:"GLOSSARY" AND object_type_srt:COLLECTION_INSTANCE',
            rows: instancePerPage,
            sort: 'last_updated_srt desc'
          }
        }
        )
        .then(response => {
          if (response.data.response.docs.length > 0) {
            collectionRecommendation['Terms'] = response.data.response.docs
          }
        })
    ]

    await Promise.all(promises).catch(error => { console.log(error) })

    dispatch({
      type: 'set_collection_recommendation',
      collectionRecommendation
    })
  }

  const sourcePerPage = 8;

  const getSourcesData = () => {

    axiosCerebrum
      .get(
        '/api/sources', {
        params: {
          page: 1,
          per_page: sourcePerPage,
          sort: 'END_DESC',
          internal_flag: false,
          active_flag: true
        }
      }
      )
      .then(response => {
        dispatch({
          type: 'set_source_recommendation',
          sourceRecommendation: response.data
        })
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    if (localStorage.hasOwnProperty('userGroupData') && !state.recommendListLoading) {
      if (!groupId) return;
      dispatch({
        type: 'set_recommend_list_data',
        recommendListLoading: true
      })
      axiosCerebrum
        .get(
          `/api/groups/${groupId.toLowerCase()}/related`, {
          params: {
            relationship: 'KEY_IN_AUTO',
            per_page: 10,
            sort: 'END_DESC'
          }
        }
        )
        .then(response => {
          if (response.data.total === 0) {
            dispatch({
              type: 'set_recommend_list_data',
              recommendListData: []
            })
            return;
          }
          axiosSolr
            .get(
              '/solr/search/select',
              {
                params: {
                  "q": "*",
                  "fl": "*",
                  "fq": `id:(${response.data.items.map(el => el.id).join(' OR ')})`,
                  "sort": "last_used_srt desc",
                  "rows": 10
                }
              }
            )
            .then(solrResponse => {
              dispatch({
                type: 'set_recommend_list_data',
                recommendListData: solrResponse.data.response.docs
              })
            })
            .catch(error => {
              dispatch({
                type: 'set_recommend_list_data',
                recommendListError: true
              })
            })
        })
        .catch(error => {
          dispatch({
            type: 'set_recommend_list_data',
            recommendListError: true
          })
        })
    }
    if (!state.sourceRecommendation) getSourcesData();
    if (!state.collectionRecommendation) getCollectionsData();
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.root}>
      {
        state.recommendListData && state.recommendListData.length > 0 && variant === 'landing' &&
        <div>
          <Typography className={classes.header} style={{ marginTop: 16 }}>
            Recommended for
            <span
              onClick={() => {
                sendMessage({ closeNavBarDrawer: true })
                history.push(`/profile/group/${groupId}`)
              }}
              style={{
                fontSize: 16,
                marginLeft: 6,
                textDecoration: 'underline', cursor: "pointer"
              }}
            >
              {groupName}
            </span>
          </Typography>
          {
            (teamKeySeeMore ? state.recommendListData : state.recommendListData.slice(0, 5)).map(el => (
              <SimpleResultItem
                key={el.id}
                title={getDispFields(el, 'dispTitle')}
                label={el.object_type_txt}
                iconLabel={getIconComponent({ label: el.source_type_txt }) ? el.source_type_txt : el.object_type_txt}
                item={el}
                subTitle={getDispFields(el, 'dispSubtitle')}
                rightText={el.trust_srt}
                showUnderline
                onClick={() => {
                  onClickResultItem({ id: el.id, item: el, label: el.object_type_txt, history: history })
                  sendMessage({ closeNavBarDrawer: true })
                }}
              />
            ))
          }
          {
            state.recommendListData.length > 5 &&
            <Button color='primary' onClick={() => setTeamKeySeeMore(!teamKeySeeMore)} style={{ marginTop: 6 }}>SEE {teamKeySeeMore ? 'LESS' : 'MORE'}</Button>
          }
        </div>
      }
      {
        state.sourceRecommendation && state.sourceRecommendation?.total > 0 && variant === 'landing' &&
        <div>
          <Typography className={classes.header}>
            Explore
            <span
              onClick={() => {
                sendMessage({ closeNavBarDrawer: true })
                history.push('/my_ecosystem?tabName=SOURCES&sourceTabName=DATABASE')
              }}
              style={{
                fontSize: 16,
                marginLeft: 6,
                textDecoration: 'underline',
                cursor: "pointer"
              }}
            >
              Sources
            </span>
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {
              state.sourceRecommendation.items.map(s => (
                <div
                  key={s.name}
                  className={classes.collectionChip}
                  onClick={() => {
                    sendMessage({ closeNavBarDrawer: true })
                    onClickResultItem({ item: s, id: s.id, label: 'source', history })
                  }}
                >
                  {getIconComponent({ label: s.source_template.name, size: 20, colour: theme.palette.primaryText.main, defaultLabel: 'source' })}
                  <span className={classes.collectionChipText}>
                    {s.name}
                  </span>
                </div>
              ))
            }
            {
              state.sourceRecommendation.total > sourcePerPage &&
              <KTooltip title={`${state.sourceRecommendation.total - sourcePerPage} more sources, click to view in my ecosystem page`}>
                <div
                  key={'more'}
                  className={classes.collectionChip}
                  onClick={() => {
                    sendMessage({ closeNavBarDrawer: true })
                    history.push('/my_ecosystem?tabName=SOURCES&sourceTabName=DATABASE')
                  }}
                >
                  <span className={classes.collectionChipText}>
                    +{state.sourceRecommendation.total - sourcePerPage}
                  </span>
                </div>
              </KTooltip>
            }
          </div>
        </div>
      }
      {
        variant !== 'landing' && state.collectionRecommendation && Object.keys(state.collectionRecommendation).length > 0 &&
        <Typography style={{ fontSize: 13.75, color: theme.palette.header.main }}>EXPLORE YOU DATA ECOSYSTEM BY</Typography>
      }
      {
        state.collectionRecommendation && ['Categories', 'Domains', 'Verified Use Cases', 'Terms'].map(el => {
          if (!state.collectionRecommendation[el]) return undefined;
          return (
            <div key={el}>
              <Typography className={classes.header} style={variant === 'landing' ? undefined : { marginBottom: 8, marginTop: 16 }}>
                {variant === 'landing' ? 'Explore' : ''}
                <span
                  id={`explore_${el}_link`}
                  onClick={() => {
                    sendMessage({ closeNavBarDrawer: true })
                    if (el === 'Categories') {
                      history.push(`/profile/collection/${collectionIds.category}`);
                    }
                    if (el === 'Domains') {
                      history.push(`/profile/collection/${collectionIds.domain}`);
                    }
                    if (el === 'Verified Use Cases') {
                      history.push(`/profile/collection/${collectionIds.verifiedUseCase}`);
                    }
                    if (el === 'Terms') {
                      let term = state.collectionRecommendation[el][0];
                      let objectType = getCollectionObjectName({ collectionName: term.collection_txt, collectionType: term.collection_type_txt })
                      dispatch({ type: 'set_selected_object_type', selectedObjectType: objectType })
                      onSearch({ searchStr: '*', objectType: objectType, isFromLandingPage: true })
                      history.push(`/basic_search?query=*&objectType=${objectType}`)
                    }
                  }}
                  style={{
                    fontSize: variant === 'landing' ? 16 : 13.75,
                    marginLeft: variant === 'landing' ? 6 : 0,
                    textDecoration: 'underline',
                    cursor: "pointer"
                  }}
                >
                  {el}
                </span>
              </Typography>
              <div style={{ display: 'flex', flexWrap: "wrap" }}>
                {
                  state.collectionRecommendation[el].map(c => (
                    <div
                      className={classes.collectionChip}
                      style={{ height: 24, borderRadius: 12, background: el === 'Categories' ? c.properties[1] : theme.palette.chip.main }}
                      onClick={() => {
                        onClickResultItem({ item: c, id: c.id, label: 'collection_instance', history })
                        sendMessage({ closeNavBarDrawer: true })
                      }}
                    >
                      <span
                        className={classes.collectionChipText}
                        style={{
                          color: el === 'Categories' ? getFontColourByBackground(c.properties[1]) : undefined
                        }}
                      >
                        {c.name_txt || c.name}
                      </span>
                    </div>
                  ))
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default withTheme()(withStyles(styles)(RecommendList));
