import React, { useEffect, useRef, useState } from 'react';
import { withTheme, withStyles, Modal, Paper, Typography, Stepper, Step, StepLabel, Button, Radio, LinearProgress, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import axiosCerebrum from '../../../axios-cerebrum'
import useAlert from '../../../hooks/useAlert';
import { FileUploader } from "react-drag-drop-files";
import SearchSelector from '../../UI/SearchSelector/SearchSelector';
import { getIconComponent, sendMessage } from '../../../utilities';
import { tileStyles } from '../../UI/ProfileSideBar/Tiles/utils/styles';

const styles = theme => ({
  title: {
    fontSize: 20,
    fontWeight: '500',
    color: theme.palette.header.main,
    marginBottom: 24
  },
  selector: {
    ...theme.components.header,
    '& div div': {
      padding: '4px 24px 4px 8px',
      fontSize: 20
    },
    '& svg': {
      color: theme.palette.header.main
    },
    border: `1px solid ${theme.palette.header.main}`,
    color: theme.palette.header.main,
    borderRadius: 4,
    fontSize: 20,
  },
  stepper: {
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 24,
    backgroundColor: theme.palette.background.main,
    // paddingLeft:0
  },
  step: {
    '& svg': {
      color: theme.palette.primaryText.light
    },
  },
  subHeader: {
    fontSize: 12,
    color: theme.palette.primaryText.main,
    marginBottom: 8,
    letterSpacing: 1.5

  },
  customScroll: {
    ...theme.components.customScroll
  },
  dropZone: {
    width: '100%',
    boxSizing: 'border-box',
    height: 56,
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.hovered.main
    }
  },
  collectionTag: {
    ...tileStyles.tag,
    background: theme.palette.primary.dark,
    cursor: 'default',
    '&:hover': {
    }
  },
  collectionTagText: {
    ...tileStyles.tagText,
    color: theme.palette.background.main,
    cursor: 'default',
    '&:hover': {

    }
  },
  optionList: {
    display: 'flex',
    alignItems: 'flex-start',
    borderRadius: 3,
    padding: '8px 0px',
    cursor: 'pointer',
    "&:hover": {
      background: theme.palette.hovered.main,
    }
  },
  optionTitile: {
    fontSize: 16,
    color: theme.palette.primaryText.main
  },
  optionDescription: {
    fontSize: 13.75,
    color: theme.palette.primaryText.light
  },
  fileTag: {
    ...tileStyles.tag,
    background: theme.palette.primary.dark,
    cursor: 'default',
    '&:hover': {
    }
  },
  fileText: {
    ...tileStyles.tagText,
    color: theme.palette.background.main,
    cursor: 'default',
    '&:hover': {

    }
  },
  iconButton: {
    ...tileStyles.deleteButton,
    display: "block",
    marginRight: 4,
    marginLeft: 8
  }
})

function ImportSourceModal(props) {

  const {
    theme,
    classes,
    state,
    dispatch,
    modalOpen,
    setModalOpen,
    history,
  } = props;

  const [step, setStep] = useState(0)
  const selectedSource = state.importSource;
  const setSelectedSource = (source) => {
    dispatch({
      type: 'set_import_source',
      importSource: source
    })
  }

  const [searchValue, setSearchValue] = useState('')
  const [importType, setImportType] = useState('update_only')
  const [error, setError] = useState(false)
  const [file, setFile] = useState(null)
  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(false)

  const isCancelledRef = useRef(false)


  const {
    sendAlert
  } = useAlert({
    isCancelledRef
  })

  useEffect(() => {
    return () => {
      isCancelledRef.current = true
    }
  }, [])


  useEffect(() => {
    if (!modalOpen) {
      setFile()
      setError(false)
      setUploading(false)
      setUploadError(false)
      setSelectedSource()
      setImportType('update_only')
      setStep(0)
    }
    // eslint-disable-next-line
  }, [modalOpen])

  const onFileDrop = (f) => {
    setError(false)
    setFile(f)
  }

  const onConfirm = () => {
    let sourceId = selectedSource.source_id || selectedSource.id
    let url = `/api/sources/${sourceId}/bulk?action=UPDATE`;
    if (importType === 'delete_reload') {
      url = `/api/sources/${sourceId}/bulk?action=RELOAD`;
    }

    setUploading(true)
    let formData = new FormData();
    formData.append("data", file);
    axiosCerebrum
      .post(
        url,
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      )
      .then(response => {
        setUploading(false)
        sendAlert({ type: 'info', message: 'Template successfully uploaded' })
        sendMessage({ reload_bulk_history: true })
        setModalOpen(false)
        history.push(`/my_tasks?tabName=JOBS&jobName=MANUAL SOURCE IMPORT`)
      })
      .catch(error => {
        console.log(error)
        setUploading(false)
        setUploadError(typeof (error?.response?.data?.errors) === 'string' ? error?.response?.data?.errors : 'Template failed to upload')
      })
  }

  const getStepContent = (index) => {
    if (index === 0) {
      return (
        <div className={classes.customScroll} style={{ maxHeight: '40vh', overflow: 'auto', paddingRight: 24 }}>
          <Typography className={classes.subHeader}>SOURCE</Typography>
          <SearchSelector
            url="/api/sources"
            testID={'import-source-search'}
            params={{
              active_flag: true,
              types: 'DATABASE,TOOL,IDENTITY_STORE',
              sort: 'ALPHABETICAL',
              job_mode: 'LOAD_FROM_EXCEL',
              per_page: 200
            }}
            isStatic
            endAdornment={modalOpen?.forceSource ? <div></div> : null}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            placeholder={modalOpen?.forceSource ? 'Source is already selected' : 'Select a Source'}
            onResultClick={el => setSelectedSource(el)}
            disabled={modalOpen?.forceSource}
            isCerebrum
            scrollable
            autoSuggestion
            removeSuggestionHeader
            postProcessDataList={data => {
              return data.filter(el => searchValue.trim() === '' || el.name.toLowerCase().includes(searchValue.toLowerCase()))
            }}
          />
          {
            selectedSource &&
            <div style={{ marginTop: 16 }}>
              <Typography className={classes.subHeader}>SELECTED</Typography>
              <div className={classes.collectionTag}>
                <span className={classes.collectionTagText}>{selectedSource.name}</span>
              </div>
            </div>
          }

          <div style={{ paddingTop: 16, marginTop: 24, borderTop: `1px solid ${theme.palette.listItemDivider.main}` }}>
            <Typography className={classes.subHeader}>IMPORT TYPE</Typography>
            {
              [
                { label: 'Update only', description: 'Applies creates and updates based on the file uploaded.', value: 'update_only' }
                // {label:'Delete and reload',description:'Deletes all metadata and manual linkages. Reloads the Source from file.',value:'delete_reload'},
              ].map(el => (
                <div className={classes.optionList} onClick={() => setImportType(el.value)}>
                  <Radio
                    checked={importType === el.value}
                    color='primary'
                    style={{ marginRight: 8 }}
                  />
                  <div style={{ flexGrow: 1 }}>
                    <Typography className={classes.optionTitile}>
                      {el.label}
                    </Typography>
                    <Typography className={classes.optionDescription}>
                      {el.description}
                    </Typography>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      )
    }
    if (index === 1) {
      return (
        <div className={classes.customScroll} style={{ maxHeight: '40vh', overflow: 'auto', paddingRight: 24 }}>
          <Typography className={classes.subHeader}>FILE UPLOAD</Typography>
          <FileUploader
            required={true}
            key={file?.name || "empty"}
            handleChange={onFileDrop}
            maxSize={50}
            types={['XLSX']}
            name={'template'}
            dropMessageStyle={{ display: 'none' }}
            onSizeError={() => setError('size')}
            onTypeError={() => setError('type')}
            children={
              <div className={classes.dropZone}>
                {
                  !error &&
                  <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.light }}>Click or drag to add a template</Typography>
                }
                {
                  error === 'size' &&
                  <Typography style={{ fontSize: 13.75, color: theme.palette.error.main }}>File size must be less than 50MB</Typography>
                }
                {
                  error === 'type' &&
                  <Typography style={{ fontSize: 13.75, color: theme.palette.error.main }}>File type must be XLSX or ZIP</Typography>
                }
              </div>
            }
          />
          {
            file &&
            <div style={{ paddingTop: 16, marginTop: 24, borderTop: `1px solid ${theme.palette.listItemDivider.main}` }}>
              <Typography className={classes.subHeader}>
                FILE SELECTED
              </Typography>
              <div className={classes.fileTag}>
                <span className={classes.fileText}>{file.name}</span>
                <IconButton className={classes.iconButton} onClick={event => { event.stopPropagation(); setFile() }}>
                  {getIconComponent({ label: 'clear', size: 13.75, colour: theme.palette.background.main })}
                </IconButton>
              </div>
            </div>
          }
        </div>
      )
    }
  }

  const steps = [
    'Set import options',
    'Upload file'
  ]

  const checkClickable = step => {
    if (step === 0) return selectedSource ? true : false;
    if (step === 1) return (file ? true : false) && checkClickable(0);
  }

  const onCreateTemplate = (id) => {
    const payload = {
      "domain": document.location.protocol + "//" + document.location.hostname,
      "filters": {
        "source_id": id,
        "collection_ids": []
      },
      "ignore_cache": true,
      "lookup_code": "bulk_edit_source_excel",
      "type": "BULK_EDIT_SOURCE_EXCEL"
    }
    axiosCerebrum
      .post('/api/extracts', payload)
      .then(extractResponse => {
        history.push(`/my_tasks?tabName=EXTRACTS&jobName=MANUAL SOURCE FILE`)
      })
      .catch(error => {
        console.log(error)
        sendAlert({ message: "Error occurred submitting the request, please try again", type: 'error' })
      })
  }

  const createModeInput = (
    <div>
      {
        step < 2 &&
        <>
          <Stepper activeStep={step} className={classes.stepper} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index} className={classes.step}>
                <StepLabel>
                  <Typography color='primary' style={{ color: step < index ? theme.palette.primaryText.light : theme.palette.primary.main, fontSize: 16, marginLeft: 6 }}>
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(step)}
        </>
      }
      {
        step === 2 && !uploading && !uploadError &&
        <div style={{ fontSize: 14, color: theme.palette.primaryText.light, whiteSpace: 'pre-wrap' }}>
          <span>
            You have selected to update the <span style={{ fontWeight: 700 }}>{selectedSource.name}</span> source with the following file:
            {'\n\n'}
            <span style={{ fontWeight: 700 }}>
              {file.name}
            </span>
            {'\n\n'}
            Click YES to proceed.
          </span>
          {
            // importType==='update_only' && 
            // <span>
            //   You have selected the <span style={{fontWeight:700}}>update only</span> option to update the <span style={{fontWeight:700}}>{selectedSource.name}</span> source with the following file:
            //   {'\n\n'}
            //   <span style={{fontWeight:700}}>
            //     {file.name}
            //   </span>
            //   {'\n\n'}
            //   Click YES to proceed.
            // </span>
          }
          {
            // importType==='delete_reload' &&
            // <span>
            //   You have selected the <span style={{fontWeight:700}}>delete & reload</span> option to update the <span style={{fontWeight:700}}>{selectedSource.name}</span> source with the following file:
            //   {'\n\n'}
            //   <span style={{fontWeight:700}}>
            //     {file.name}
            //   </span>
            //   {'\n\n'}
            //   After uploading your file, K will delete the source and reload it using the file provided.
            //   {'\n\n'}
            //   All linkages to instances deleted will be removed.
            //   {'\n\n'}
            //   Click YES to proceed.
            // </span> 
          }
        </div>
      }
      {
        step === 2 && uploading &&
        <div style={{ marginTop: 40, marginBottom: 40 }}>
          <div style={{ textAlign: 'center' }}>
            <LinearProgress color='secondary' style={{ height: 6, width: '80%', margin: 'auto', marginTop: 32 }} />
            <Typography style={{ fontSize: 13.75, color: theme.palette.primaryText.light, marginTop: 16 }}>Upload in progress</Typography>
          </div>
        </div>
      }
      {
        step === 2 && uploadError &&
        <div style={{ whiteSpace: 'pre-wrap', fontSize: 14, color: theme.palette.primaryText.light }}>
          <span>
            {uploadError}
          </span>
          <Button color='primary' variant='contained' style={{ width: '100%', marginTop: 40, marginBottom: 40 }} onClick={() => onCreateTemplate(selectedSource.source_id || selectedSource.id)}>
            DOWNLOAD LATEST TEMPLATE FOR {selectedSource.name}
          </Button>
        </div>
      }
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginBottom: -12, marginTop: 24 }}>
        {
          step > 0 &&
          <Button color='primary' data-test-id="import-back-button" style={{ marginRight: 8 }} onClick={() => { setStep(step - 1) }}>
            BACK
          </Button>
        }
        {
          step < 2 &&
          <>
            <Button color='primary' data-test-id="import-next-button" style={{ marginRight: 8 }} disabled={!checkClickable(step)} onClick={() => { setStep(step + 1) }}>
              {step === 1 ? 'IMPORT' : 'NEXT'}
            </Button>
          </>
        }
        {
          step === 2 &&
          <>
            {
              uploading || uploadError ?
                undefined
                :
                <Button color='primary' data-test-id="import-update-button" style={{ marginRight: 8 }} onClick={onConfirm}>
                  YES
                </Button>
            }
          </>
        }
        <Button
          color='secondary'
          onClick={() => {
            setModalOpen(false)
          }}
        >
          {uploading || uploadError ? 'CLOSE' : 'CANCEL'}
        </Button>
      </div>
    </div>

  )

  return (
    <Modal
      open={modalOpen}
      onClose={() => setModalOpen(false)}
      disableBackdropClick={true}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div style={{ outline: 'none', width: 746, margin: 'auto' }}>
        <Paper style={{ width: 720, padding: 24, background: theme.palette.background.main, border: `1px solid ${theme.palette.border.main}` }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.title}>
              {
                step === 2 ?
                  uploading ? 'Source file upload' : uploadError ? 'Error with uploading source file' : 'Are you sure?' :
                  'Import Source File'
              }
            </Typography>
          </div>
          {
            createModeInput
          }
        </Paper>
      </div>
    </Modal>
  )
}

ImportSourceModal.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
}

export default withTheme()(withStyles(styles)(ImportSourceModal));