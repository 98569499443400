import moment from 'moment';
import axiosCerebrum from '../../../axios-cerebrum';
import { mapObjectName, collectionIds, getLabelPlural, getIconComponent, toTitleCase, } from '../../../utilities'
import { getIconLabel } from '../SearchResults/utils';
import axiosSolr from '../../../axios-solr';
export const hasAccessInfo = ['CONTENT','TABLE','DATASET','CONTENT_APP']
export const noAccessInfo = ['DATABASE','SCHEMA','DATA_PIPELINE','ML_MODEL','FILE']
export const hasParentAccessInfo = ['COLUMN','DATASET_TABLE','DATASET_COLUMN','CONTENT_CHILD']


export const loadAccessInfo = ( data, sessionData, onAddTileData) => {

  if(!data.object || !hasAccessInfo.includes(data.object.name))return;
  axiosCerebrum
    .get(
      `/api/collectioninstances/members`,
      {params:{
        collection_id:collectionIds.accessRole,
        ids:`${data.id}`,
        per_page:0
      }}
    )
    .then(response=>{
      if(response.data.total===0){
        return;
      }else{
        axiosCerebrum
          .get(
            `/api/collectioninstances/members`,
            {params:{
              collection_id:collectionIds.accessRole,
              ids:`${sessionData.id},${data.id}`,
              per_page:0
            }}
          )
          .then(res=>{
            onAddTileData({
              isInfoOnly:true,
              id:"hasAccess",
              value:res.data.total>0
            })
          })
      }
    })
 
}

export const generateMainData = async ({data,loading,mapping,onAddTileData, setLoading, history, tabOptions, sessionData, alwaysOpenNewTab}) => { 
  if(loading)return;
  setLoading(true)
  let promises = [];
  loadAccessInfo(data, sessionData, onAddTileData)
  if((mapping.includes('sourceType') || mapping.includes('sourceType_no_location')) && data.source_type_txt && data.object){
    let dispBody = {};
    let sourceTypeName = data.source_type_txt.replace(/_/g,' ')
    if(['CONTENT_CHILD','CONTENT','DATA_PIPELINE','DATASET','DATASET_TABLE','DATASET_FIELD','CONTENT_APP'].includes(data.object.name) && data.type){
      dispBody = {"disp_value":sourceTypeName + ' ' + data.type.toUpperCase(),"labels":data.source_type_txt}
    }else{
      let label = mapObjectName(data.object.name).replace(/_/g,' ');
      if(label==='HOST')label = '';
      if(label==='DQ TEST'){
        label = 'DQ TEST';
        if(['DBT CORE','DBT CLOUD'].includes(sourceTypeName))sourceTypeName = 'DBT'
      }
      if(label==='TABLE' && data.table_type_txt && data.table_type_txt!=='TABLE')label=data.table_type_txt;
      if(label==='CONTENT_APP')label='APP'
      dispBody =  {"disp_value":sourceTypeName + ' ' + label,"labels":data.source_type_txt}
    }

    if(data.location){
      dispBody.disp_subtext = data.location
    }
    
    onAddTileData(
      {
        "disp_body":[dispBody],
        "disp_title":"TYPE",
        "disp_type":"text",
        "id": mapping.includes('sourceType')?"sourceType":'sourceType_no_location'
      }
    )
  }
  if((mapping.includes('sourceType') || mapping.includes('sourceType_no_location')) && (!data.source_type_txt || !data.object)){
    let dispBody;
    if(data.source_template){
      dispBody = {"disp_value":data.source_template.name,"labels":data.source_template.name}
    }
    if(data.source_data && data.source_data.source_template){
      dispBody = {"disp_value":data.source_data.source_template.name,"labels":data.source_data.source_template.name}
    }
    if(dispBody && data.location && !mapping.includes('sourceType_no_location')){
      dispBody.disp_subtext = data.location
    }
    if(dispBody){
      onAddTileData(
        {
          "disp_body":[dispBody],
          "disp_title":"TYPE",
          "disp_type":"text",
          "id": mapping.includes('sourceType')?"sourceType":'sourceType_no_location'
        }
      )
    }
  }

  if(mapping.includes('sourceType') && data.source_obj && data.source_obj.name?.toUpperCase()!=='K PLATFORM'){
    onAddTileData(
      {
        "disp_body": [{
          "disp_value": data.source_obj.name,
          "disp_subtext":data.source_obj.source_template.name?data.source_obj.source_template.name:undefined,
          "labels": getIconComponent({label:data.source_obj.source_template.name})?data.source_obj.source_template.name:'source',
          onClick: ()=>{
            alwaysOpenNewTab?
            window.open(`/profile/source/${data.source_obj.host_ids[0]}`,'_blank'):
            history.push(`/profile/source/${data.source_obj.host_ids[0]}`)
          }
        }],
        "disp_title": "SOURCE",
        "id": "sourceType"
      },
    )
  }
  
  if(mapping.includes('dataType') && (data.data_type || data.type)){
    if(data.object.name!=='DATASET_FIELD' || data.data_type){
      onAddTileData(
        {
          "disp_body":[{disp_value:(data.data_type || data.type).toUpperCase()}],
          "disp_title":"DATA TYPE",
          "disp_type":"text",
          "id":"dataType"
        }
      )
    }
  }

  if(mapping.includes('masked') && data.masked_txt==='YES'){
    onAddTileData(
      {
        "disp_body":[{disp_value:'YES'}],
        "disp_title":(data.object?.name || data.object_type_txt)==='COLUMN'?'MASKED':'MASKING APPLIED',
        "disp_type":"text",
        "id":"masked"
      }
    )
  }
  
  if(mapping.includes('source') && data.source_txt && data.source_txt!=='K PLATFORM'){
    onAddTileData(
      {
        "disp_body":[{disp_value:data.source_txt +(data.source_type_txt?` (${data.source_type_txt})`:''),label:data.source_type_txt}],
        "disp_title":"SOURCE",
        "disp_type":"text",
        "id":"source"
      }
    )
  }
  if(mapping.includes('trustscore')){
    let score = 0;
    if(data.score)score = data.score;
    if(data.trustScore)score = data.trustScore;
    onAddTileData(
      {
        "disp_body":[
          {"disp_type":"text","disp_value":score,"labels":"","nodeKey":""},
          {"disp_type":"lastCalculatedTime","disp_value":data.last_updated_at || data.updated,"labels":"","nodeKey":""}
        ],
        "disp_data":{"datasets":[],"labels":[]},
        "disp_footer":"",
        "disp_title":"Trust Score",
        "disp_type":"text",
        "id":"trustscore"
      }
    )
  }
  if(mapping.includes('lastUsed')){
    const onClickLastUsed = () => {
      let sub_tab_index;
      let timeDiff = moment().diff(moment(data.last_used_at),'day')
      if(timeDiff<=90){
        sub_tab_index = 3;
      }
      else if(timeDiff<=180){
        sub_tab_index = 6;
      }
      else{
        sub_tab_index = 12
      }
      window.postMessage({sidebar_action:'open_usage',sub_tab_index},document.location.protocol + "//" + document.location.hostname+':'+document.location.port)
    }
    onAddTileData({
      "disp_body":data.last_used_at?[
        {"disp_type":"date","disp_value":data.last_used_at,"labels":"","nodeKey":"",onClick:onClickLastUsed}
      ]:'Unknown',
      "disp_data":{"datasets":[],"labels":[]},
      "disp_title":"Last Used",
      "disp_type":"text",
      "id":"lastUsed",
      "box_action":tabOptions && tabOptions.includes('USAGE')?
        [{
          action:onClickLastUsed,
          text:'OPEN USAGE'
        }]
        :undefined,
    })
  }
  if(mapping.includes('collectionType')){
    let boxes;
    let onClick;
    // if(data?.object?.name==='COLLECTION'){
    //   onClick = ()=>{
    //     alwaysOpenNewTab?
    //     window.open(`/profile/collection/${data.id}`,'_blank'):
    //     history.push(`/profile/collection/${data.id}`)
    //   }
      
    // }
    // if(data?.object?.name==='COLLECTION_INSTANCE' && data.parent){
    //   onClick = ()=> {
    //     alwaysOpenNewTab?
    //     window.open(`/profile/collection/${data.parent.id}`,'_blank'):
    //     history.push(`/profile/collection/${data.parent.id}`)
    //   }
    //   boxes = [
    //     {
    //       "disp_type":'text',
    //       "disp_value": `${data&&(data.category || (data.parent && data.parent.category)).split('_').join(' ')}`,
    //     },
    //     {
    //       "disp_type":'text',
    //       "disp_value": `${data&&(data.collection_name || (data.parent && data.parent.name)).split('_').join(' ')}`,
    //       label:getIconLabel({label:'COLLECTION',item:data.parent})
    //     }
    //   ]
    // }
    boxes = [
      {
        "disp_type":'text',
        "disp_value": `${data&&toTitleCase((data.category || (data.parent && data.parent.category)).split('_').join(' '))}`,
        label:getIconLabel({label:'COLLECTION',item:data})
      }
    ]
    if(boxes){
      onAddTileData({
        "disp_body": boxes,
        onClick:onClick,
        "disp_title": "Collection Type",
        "disp_type": "text",
        "id": "collectionType"
      })
    }
  }
  if(mapping.includes('lastUpdated')){
    onAddTileData({
      "disp_body": [
        {
          "disp_type":'date',
          "disp_value": `${data&&data.updated_at}`
        }
      ],
      "disp_title": "Profile updated",
      "disp_type": "date",
      "id": "lastUpdated"
    })
  }
  if(mapping.includes('lastRun') ){
    onAddTileData({
      "disp_body": [
        {
          "disp_type":'date',
          "disp_value": `${data&&data.last_used_at}`
        }
      ],
      "disp_title": 'Last Run',
      "disp_type": "date",
      "id": "lastRun"
    })
  }
  if(mapping.includes('runs') && !isNaN(data?.frequency)){
    onAddTileData({
      "disp_body": [
        {
          "disp_value": data?.frequency
        }
      ],
      "disp_title": 'Runs',
      "id": "runs"
    })
  }
  if(mapping.includes('createdOn')){
    onAddTileData({
      "disp_body": [
        {
          "disp_type":'date',
          "disp_value": `${data&&data.created_at}`
        }
      ],
      "disp_title": "Created On",
      "disp_type": "date",
      "id": "createdOn"
    })
  }
  if(mapping.includes('createdBy') && data.created_by){
    onAddTileData(
      {
        "disp_body":[{"disp_value":data.created_by.name}],
        "disp_title": "Created By",
        "id": 'createdBy',
        "link": `/profile/user/${data.created_by.id}`
      }
    )
  }
  if(mapping.includes('updatedby') && data.updated_by){
    onAddTileData(
      {
        "disp_body":[{"disp_value":data.updated_by.name}],
        "disp_title": "LAST UPDATED BY",
        "id": 'lastUpdatedBy',
        "link": `/profile/user/${data.updated_by.id}`
      }
    )
  }

  let relationship = [];
  if(mapping.includes('createdBy') && !data.created_by)relationship.push('CREATED_BY');
  if(mapping.includes('owner'))relationship.push('OWNED_BY');
  if(mapping.includes('stewardedBy'))relationship.push('STEWARDED_BY');
  if(mapping.includes('verified'))relationship.push('VERIFIED_BY');
  if(mapping.includes('verifiedNot'))relationship.push('NOT_VERIFIED_BY');
  if(mapping.includes('classification'))relationship.push('CLASSIFIED_BY');
  if(mapping.includes('lastUpdatedBy') && !data.updated_by)relationship.push('UPDATED_BY');
  if(relationship.length>0){
    promises.push(
      axiosCerebrum
        .get(`/api/${getLabelPlural(data.object.name)}/${data.id}/related?relationship=${relationship.join(',')}&sort=LAST_USED_DESC&per_page=200`)
        .then(response=>{
          if(!data.created_by){
            if(mapping.includes('createdBy')){
              let creator = response.data.items.find(el=>el.relationship==='CREATED_BY')
              if(creator){
                onAddTileData(
                  {
                    "disp_body":[{"disp_value":creator.name}],
                    "disp_title": "Created By",
                    "id": 'createdBy',
                    "link": `/profile/user/${creator.id}`
                  }
                )
              }else{
                onAddTileData(
                  {
                    "disp_body":"Unknown",
                    "disp_title": "Created By",
                    "id": 'createdBy',
                    "link": null
                  }
                )
              }
            }
          }

          if(!data.updated_by){
            if(mapping.includes('lastUpdatedBy')){
              let updator = response.data.items.find(el=>el.relationship==='UPDATED_BY')
              if(updator){
                onAddTileData(
                  {
                    "disp_body":[{"disp_value":updator.name}],
                    "disp_title": "LAST UPDATED BY",
                    "id": 'lastUpdatedBy',
                    "link": `/profile/user/${updator.id}`
                  }
                )
              }
            }
          }
        })
    )
  }

  if(mapping.includes('topTeams')){
    let ids = data.top_team_ids_txts;
    if(ids?.length>0){
      axiosSolr
        .get(
          `/solr/search/select`,{params:{
            q:"*",
            fq:`id:(${ids.join(' OR ')})`,
            rows:ids.length,
          }}
        )
        .then(response=>{
          onAddTileData(
            {
              "disp_body":response.data.response.docs.map(el=>({
                labels:'group',
                name:el.name_txt,
                id:el.id
              })),
              "disp_data":{"datasets":[],"labels":[]},
              "disp_footer":"",
              "disp_title":"TOP TEAMS",
              "disp_type":"list",
              "id":"topTeams",
            }
          )
        })
    }else{
      onAddTileData(
        {
          "disp_body":"No team found",
          "disp_title":"TOP TEAMS",
          "disp_type":"list",
          "id":"topTeams",
        }
      )
    }
  }

  if(mapping.includes('jobTypes')){
    axiosCerebrum
      .get(
        `/api/${getLabelPlural(data.object.name)}/${data.id}/related/collections`,{params:{
          per_page:50,
          relationship:'MEMBER_OF_AUTO',
          parent_name:'Job Type'
        }}
      )
      .then(response=>{
        onAddTileData(
          {
            "disp_body":response.data.items.map(el=>({
              labels:'collection_instance',
              name:el.name,
              disp_value:el.name,
              id:el.id
            })),
            "disp_data":{"datasets":[],"labels":[]},
            "disp_footer":"",
            "disp_title":"JOB TYPES",
            "id":"jobTypes",
          }
        )
      })
  }

  if(mapping.includes('topTools')){
    promises.push(
      axiosCerebrum
        .get(`/api/${getLabelPlural(data.object.name)}/${data.id}/calculated/related?type=TOP_TOOLS&per_page=3`)
        .then(response=>{
          if(response.data.total===0){
            onAddTileData(
              {
                "disp_body":"No tool found",
                "disp_title":"Top Tools",
                "disp_type":"list",
                "id":"topTools"
              }
            )
          }else{
            onAddTileData(
              {
                "disp_body":response.data.items.map(el=>({
                  labels:el.type.toLowerCase(),
                  name:el.name,
                  id:el.id
                })),
                "disp_data":{"datasets":[],"labels":[]},
                "disp_footer":"",
                "disp_title":"Top Tools",
                "disp_type":"list",
                "id":"topTools"
              }
            )
          }
        })
    )
  }

  if(mapping.includes('topSources')){
    promises.push(
      axiosCerebrum
        .get(`/api/${getLabelPlural(data.object.name)}/${data.id}/related?object_name=DATABASE&relationship=VIEWS&sort=USAGE_DESC&per_page=3`)
        .then(response=>{
          if(response.data.total===0){
            onAddTileData(
              {
                "disp_body":"No source found",
                "disp_title":"Top Sources",
                "disp_type":"list",
                "id":"topSources"
              }
            )
          }else{
            onAddTileData(
              {
                "disp_body":response.data.items.map(el=>({
                  labels:el.type.toLowerCase(),
                  disp_value:el.name,
                  id:el.id
                })),
                "disp_data":{"datasets":[],"labels":[]},
                "disp_footer":"",
                "disp_title":"Top Sources",
                "disp_type":"list",
                "id":"topSources"
              }
            )
          }
        })
    )
  }

  if(mapping.includes('topcontent')){
    promises.push(
      axiosCerebrum
        .get(`/api/${getLabelPlural(data.object.name)}/${data.id}/related?object_name=CONTENT&relationship=REFERENCED_BY&sort=USAGE_DESC&per_page=3`)
        .then(response=>{
          if(response.data.total===0){
            onAddTileData(
              {
                "disp_body":"No content found",
                "disp_title":"Top Content",
                "disp_type":"list",
                "id":"topcontent"
              }
            )
          }else{
            onAddTileData(
              {
                "disp_body":response.data.items.map(el=>({
                  labels:el.type.toLowerCase(),
                  name:el.name,
                  id:el.id
                })),
                "disp_data":{"datasets":[],"labels":[]},
                "disp_footer":"",
                "disp_title":"Top Content",
                "disp_type":"list",
                "id":"topcontent"
              }
            )
          }
        })
      )
    }

    if(mapping.includes('topdata')){
      promises.push(
        axiosCerebrum
          .get(`/api/${getLabelPlural(data.object.name)}/${data.id}/related?object_name=TABLE&relationship=REFERENCED_BY&sort=USAGE_DESC&per_page=3`)
          .then(response=>{
            if(response.data.total===0){
              onAddTileData(
                {
                  "disp_body":"No data found",
                  "disp_title":"Top Data",
                  "disp_type":"list",
                  "id":"topdata"
                }
              )
            }else{
              onAddTileData(
                {
                  "disp_body":response.data.items.map(el=>({
                    labels:el.type.toLowerCase(),
                    name:el.name,
                    id:el.id
                  })),
                  "disp_data":{"datasets":[],"labels":[]},
                  "disp_footer":"",
                  "disp_title":"Top Data",
                  "disp_type":"list",
                  "id":"topdata"
                }
              )
            }
          })
      )
    }

    await Promise.all(promises).then(()=>{
      setLoading(false)
    })
    .catch(error=>{
      console.log(error)
      setLoading(false)
    })
    
}